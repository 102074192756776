import { Injectable } from '@angular/core';

@Injectable()
export class addInscriptionModel {

  constructor() { }
  public lib_niveau:string;
  public lib_classe:string;
  public matricule:string;
  public montant:any=0;
  public reduction:any=0;
  public frannexe:any=0;

}