import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

/**
 * permet de filtrer le tableau des versements
 */
@Pipe({name: 'filterTable'})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {

            return it.matricule.toLowerCase().includes(searchText) ||
                it.nom.toLowerCase().includes(searchText) ||
                it.prenom.toLowerCase().includes(searchText) ||
                it.lib_classe.toLowerCase().includes(searchText) ||
                it.montant_vers.toLowerCase().includes(searchText);
        });
    }
}

@Pipe({name: 'dateFormat'})
export class dateFormatPipe implements PipeTransform {
    transform(value: string) {
        let datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'dd/MM/yyyy');
        return value;
    };
}

/**
 * permet de filtrer le tableau des inscrpion
 */

@Pipe({name: 'filterInscription'})
export class InscriptionPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {

            return it.matricule.toLowerCase().includes(searchText) ||
                it.nom.toLowerCase().includes(searchText) ||
                it.prenom.toLowerCase().includes(searchText) ||
                it.lib_classe.toLowerCase().includes(searchText) ||
                it.montant_ins.toLowerCase().includes(searchText);
        });
    }
}

/**
 * permet de filtrer le tableau des recu
 */

@Pipe({name: 'filterRecu'})
export class RecuPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            return it.matricule.toLowerCase().includes(searchText) ||
                it.nom.toLowerCase().includes(searchText) ||
                it.prenom.toLowerCase().includes(searchText) ||
                it.lib_classe.toLowerCase().includes(searchText) ||
                it.scolarite.toLowerCase().includes(searchText) ||
                it.reliquat.toLowerCase().includes(searchText) ||
                it.date.toLowerCase().includes(searchText) ||
                it.montant_ins.toLowerCase().includes(searchText);
        });
    }

}
/**
 * permet de filtrer le tableau des recu
 */

@Pipe({name: 'filterUser'})
export class SearchUserPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            return it.nom.toLowerCase().includes(searchText) ||
                it.prenom.toLowerCase().includes(searchText) ||
                it.user_login.toLowerCase().includes(searchText) ||
                it.contact.toLowerCase().includes(searchText) ||
                it.fonction.toLowerCase().includes(searchText);
        });
    }
}

@Pipe({name: 'groupBy'})
export class GroupByPipe implements PipeTransform {
    transform(value: Array<any>, field: string): Array<any> {
        const groupedObj = value.reduce((prev, cur) => {
            if (!prev[cur[field]]) {
                prev[cur[field]] = [cur];
            } else {
                prev[cur[field]].push(cur);
            }
            return prev;
        }, {});
        const source = Object.keys(groupedObj).map(key => ({key, value: groupedObj[key]}));
        return source;
    }
}


@Pipe({name: 'pointReplacer'})
export class PointReplacerPipe implements PipeTransform {
    transform(value: string, args: any[]): string {
        if (value) {
            return value.replace(',', '.');
        }
        return '';
    }
}