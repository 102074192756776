import { CategorieModel } from './../../database/models/categorieModel.service';
import { GestionCtrl } from './../../database/controllers/gestionCtrl.service';
import { Injectable } from '@angular/core';
import { AUTHORIZATION } from "../../routes/server-root";
import { HelpersService } from 'src/app/helper/helper.service';

@Injectable()
export class GestionService {
    dataCatCompo: any[] = [];
    dataCompo: any[] = [];
    dataNiveau: any[] = [];
    dataListeNoteviewByProf: any[];
    dataCycle: any[] = [];
    dataRech: any;
    dataClasse: any[] = [];
    dataTypeMatiere: any;
    dataMatiere: any;
    dataScolarite: any[] = [];
    datagrBy: any[] = [];
    dataVCons: any[] = [];
    dataModalite: any[] = [];

    constructor(public categorieModel: CategorieModel, private gestionCtrl: GestionCtrl) {
    }


    /**************************************************
     *                                                *
     *    Methode concernant l'affichage des doonées  *
     * ************************************************
     * ************************************************
     */
    showCatCompo() {
        this.dataCatCompo = [];
        this.gestionCtrl.getCatCompo()
            .then((data) => {
                this.dataCatCompo = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
    showCompo() {
        this.dataCompo = [];
        this.gestionCtrl.getCompo()
            .then((data) => {
                this.dataCompo = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    showCycle() {
        this.dataCycle = [];
        this.gestionCtrl.getCycle()
            .then((data) => {
                this.dataCycle = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
    showAllCycle(code_ecole: any) {
        this.dataCycle = [];
        this.gestionCtrl.getAllCycle(code_ecole)
            .then((data) => {
                this.dataCycle = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }


    showNiveau() {
        this.dataNiveau = [];
        this.gestionCtrl.getNiveau()
            .then((data) => {

                this.dataNiveau = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    showTypeMatiere() {
        this.dataTypeMatiere = [];
        this.gestionCtrl.getTypeMatiere()
            .then((data) => {

                this.dataTypeMatiere = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    /************************************
     *                                  *
     *    Methode de recherche      *
     * **********************************
     * **********************************
     */
    searchNiveau(id_cycle: string, code_ecole: string) {
        this.dataRech = [];
        this.gestionCtrl.searchNiveau(id_cycle, code_ecole)
            .then((data) => {
                this.dataRech = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
    searchNiveauParam(code_ecole, stringSearch) {
        this.dataRech = [];
        this.gestionCtrl.searchNiveauParam(code_ecole, stringSearch)
            .then((data) => {
                this.dataRech = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }


    searchClasse(id_niveau?: any, code_ecole?: string) {
        this.dataClasse = [];
        this.gestionCtrl.searchClasse(id_niveau, code_ecole)
            .then((data) => {

                let vOn: Boolean = false;
                if (data.statut) {
                    this.dataClasse = data.data;

                    if (this.datagrBy.length != 0) {

                    } else {
                        this.datagrBy.push({
                            code_ecole: data.data[0].code_ecole,
                            id: data.data[0].id,
                            id_cycle: data.data[0].id_cycle,
                            id_niveau: data.data[0].id_niveau,
                            img_classe: data.data[0].img_classe,
                            lib_classe: data.data[0].lib_classe,
                            lib_cycle: data.data[0].lib_cycle,
                            lib_niveau: data.data[0].lib_niveau,
                            sigle: data.data[0].sigle,
                        });
                    }

                    for (let i = 0; i < data.data.length; i++) {
                        vOn = false;
                        for (let j = 0; j < this.datagrBy.length; j++) {

                            if (this.datagrBy[j].lib_classe === data.data[i].lib_classe) {
                                // && datagrBy[j].lib_cycle === data.data[i].lib_cycle
                                vOn = true;
                            }
                        }
                        if (!vOn) {
                            this.datagrBy.push(
                                {
                                    code_ecole: data.data[i].code_ecole,
                                    id: data.data[i].id,
                                    id_cycle: data.data[i].id_cycle,
                                    id_niveau: data.data[i].id_niveau,
                                    img_classe: data.data[i].img_classe,
                                    lib_classe: data.data[i].lib_classe,
                                    lib_cycle: data.data[i].lib_cycle,
                                    lib_niveau: data.data[i].lib_niveau,
                                    sigle: data.data[i].sigle,
                                }

                            );
                        }
                    }
                }
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
    searchClasse_param(code_ecole: string, param: string, id_niveau?: any) {
        this.dataVCons = [];
        this.gestionCtrl.searchClasse(id_niveau, code_ecole)
            .then((data) => {

                if (data.statut) {

                    for (let i = 0; i < data.data.length; i++) {
                        if (data.data[i].lib_classe === param) {
                            this.dataVCons.push(data.data[i]);
                        }
                    }
                }
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    searchMatiere(stringSearch?: string) {
        this.dataMatiere = [];
        this.gestionCtrl.seach_Matters(stringSearch)
            .then((data) => {
                this.dataMatiere = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
    /*
    liste des note par prof et par matiere
     */
    ListeNoteByProf(idClasse: any, idmatiere: any, idcompo: any) {
        this.dataListeNoteviewByProf = [];
        this.gestionCtrl.getListeNoteByProf(idClasse, idmatiere, idcompo)
            .then((data) => {
                this.dataListeNoteviewByProf = data.data;

            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
    showScolarite(code_ecole: any, annee: any, id_niveau: any) {
        this.dataScolarite = [];
        this.gestionCtrl.getScolarite(code_ecole, annee, id_niveau)
            .then((data) => {
                if (data.statut) {
                    this.dataScolarite = data.data;
                }


            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
    showModalite(code_ecole?: any, annee?: any, id_niveau?: any, echeance?: any) {
        this.dataModalite = [];
        this.gestionCtrl.getModalite(code_ecole, annee, id_niveau, echeance)
            .then((data) => {
                if (data.statut) {
                    this.dataModalite = data.data;
                }


            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    // ShowEtabBySite(idSite:any) {
    //     this.gestionCtrl.getListeNoteByProf(idSite)
    //         .then((data) => {
    //             this.dataEtabBySite = data.data;

    //         })
    //         .catch((err) => {
    //             console.log("Un probleme est survenu, merci de réessayer.");
    //         })

    // }
}
