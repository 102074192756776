import {Injectable, OnInit} from '@angular/core';
import {CookieService} from "ngx-cookie-service";


@Injectable()
export class CookiesService implements OnInit {
  //data//
  public annee:any;
  public idannee:any;
  public login: string;
  public code_ecole: number;
  public code_user: number;
  public level: string;
  public token: any;
  public tokenExp: any;
  public user_route: string;


  public panel : any;

  constructor(public cookieService: CookieService) {
    let getCookies = this.cookieService.get('lasource');
    let getCookiesConfig = this.cookieService.get('lasourceConfig');
    // JSON.parse //
    if (getCookies) {
      let jsp: any = JSON.parse(getCookies);
      this.login = jsp.login;
      this.user_route = jsp.route;
      this.code_ecole = jsp.code_ecole;
      this.code_user = jsp.code;
      this.level = jsp.level;
      this.token = jsp.token;
      this.tokenExp = jsp.exp;
      this.annee =  jsp.annee;
      this.idannee=jsp.idannee
    }

    if (getCookiesConfig) {
      let configdata : any = JSON.parse(getCookiesConfig);
      // detection et recuperation des configurations de l'hotels//
      this.panel = configdata;
    }

  }

  ngOnInit() {
  }


}
