import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import { ToolsComponent } from './tools/tools.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [ToolsComponent],
  exports: [ToolsComponent],
})
export class ToolsModule { }
