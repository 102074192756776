import { Injectable } from '@angular/core';
import { usersCtrl } from '../../database/controllers/usersCtrl.service';

@Injectable()
export class usersService {

    dataUsers: any = [];
    dataUsersEts: any = [];
    initdataUser: any = [];
    viewUp:string='list';
    constructor(public users: usersCtrl) { }


    /**************************************************
     *                                                *
     *    Methode concernant l'affichage des users    *
     * ************************************************
     * ************************************************
     */
    showUsers(paraShow?) {
        this.users.getUsers(paraShow)
            .then((data) => {
                this.dataUsers = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
    showRegister_by_ets() {
        this.users.getRegister_by_ets()
            .then((data) => {
                this.dataUsersEts = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
}
