import {Injectable} from '@angular/core';

@Injectable()
export class addVersementModel {

    constructor() {
    }

    public lib_niveau: string;
    public id_classe: string;
    public matricule: string;
    public montant: any;
    public reduction: any = 0;
}