import { Injectable } from '@angular/core';

@Injectable()
export class EleveModel {

  constructor() { }
  public matricule:string;
  public statut:string;
  public nom:string;
  public prenom:string;
  public datenaiss:string;
  public lieunaiss:string;
  public sexe:string;
  public contact:string;
  public nationalite:string;
  public photo:any;
  
  public code_parent:string;
  public nom_parent:string;
  public prenom_parent:string;
  public fonction:string;
  public typeparent:string;
  public domicile:string;
  public email_p:string;
}