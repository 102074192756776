import {Component, OnInit, OnDestroy} from '@angular/core';
import * as XLSX from 'xlsx';
import {EleveCtrl} from "../../../database/controllers/eleveCtrl.service";
import {EleveModel} from "../../../database/models/eleveModel.service";
declare let $: any;

type AOA = any[][];

@Component({
    selector: 'parcours-cmp',
    templateUrl: './parcours.component.html',
    styleUrls: ['./parcours.component.css']
})
export class parCoursComponent implements OnInit {

    constructor(private eveleCtrl: EleveCtrl, private ecoleModel: EleveModel,) {
    }

    ngOnInit(): void {
        // ajout de la classe JS à HTML
        document.querySelector("html").classList.add('js');
    }

    /*
     import excel
     */

    data: AOA = [];
    wopts: XLSX.WritingOptions = {bookType: 'xlsx', type: 'array'};
    fileName: string = 'SheetJS.xlsx';

    onFileChange(evt: any) {
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(evt.target);

        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1}));
        };
        reader.readAsBinaryString(target.files[0]);
    }


    /*
     export de fichier excel
     */
    exportExcel(): void {
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, this.fileName);
    }

    ImporteExcle(): void {
        $('#someTable tbody tr').each((tr_idx, tr) => {

            $(tr).children('td').each((td_idx, td) => {
                if (tr_idx > 0) {

                    for (let y = 0; y < td_idx; y+=1) {
// console.log(y)
                        //console.log('[' + tr_idx + ',' + td_idx + '] => ' + $(td).text());
                        console.log('[' + 1 + ',' + 0 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 1 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 2 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 3 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 4 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 5 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 6 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 7 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 8 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 9 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 10 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 11 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 12 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 13 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 14 + '] => ' + $(td).text());
                        // console.log('[' + tr_idx + ',' + 15 + '] => ' + $(td).text());
                        //do something with every cell here
                    }

                    // console.log('[' + tr_idx + ',' + td_idx + '] => ' + $(td).text());

                }

            });

        });

        return;

        $("#tblOne tr td").each(function (index, td) {

            //console.log(index);
            console.log(td);

            // this.eveleCtrl.createEleves(this.ecoleModel)
            //     .then((data) => {
            //         if (data.statut == true) {
            //         } else {
            //         }
            //     })
            //     .catch((err) => {
            //         console.log(")-,-");
            //     })
        });

        // $('table > tbody  > tr').each(function(index, tr) {
        //     console.log(index);
        //     console.log(tr);
        // });
    }
}
