import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { _SERVEROOT, AUTHORIZATION } from '../../routes/server-root';
import { ScolariteModel } from '../models/scolariteModel.service';

declare let $: any;
declare let Promise: any;

@Injectable()
export class ScolariteCtrl {
    constructor(public http: HttpClient) {
    }
    

    /*************************
     * 
     *  Toutes les methodes pour la categorie
     * 
     *************************
     */

    createScolarite(data: ScolariteModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    lib_niveau: data.lib_niveau,
                    scolarite: data.scolarite,
                    annee:AUTHORIZATION.ecoParam.idannee
                }
                ;
            this.http.post(_SERVEROOT.addScolarite, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    upScolarite(data: ScolariteModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    lib_niveau: data.lib_niveau,
                    scolarite: data.scolarite,
                    id_sco: data.idscolarite,
                    annee:AUTHORIZATION.ecoParam.idannee
                }
                ;
            this.http.post(_SERVEROOT.updateScolarite, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getScolarite(){
        return new Promise((resolve,reject)=>{
            this.http.get(_SERVEROOT.getCatCompo+"?code_ecole="+AUTHORIZATION.ecoParam.code_ecole,AUTHORIZATION.token)
            .subscribe(
                (data)=>{
                    resolve(data)
                },
                (err)=>{ 
                    reject(err)
                }
            )
        })
    }

    
}