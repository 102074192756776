import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { _SERVEROOT, AUTHORIZATION } from '../../routes/server-root';

declare let $: any;
declare let Promise: any;

@Injectable()
export class NotesCtrl {
    constructor(public http: HttpClient) {
    }



}