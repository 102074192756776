import {Injectable} from '@angular/core';
import {MoyCtrl} from "../../database/controllers/moyCtrl.service";
import {HelpersService} from "../../helper/helper.service";

@Injectable()
export class MoyennesService {
    dataMoyCompo: any[];
    dataClasse: any[]=[];
    ListdataMoyCompo: any[];
    dataMoyCompoFIN: any[] = [];
    dataLisMat: any[] = [];
    dataLisEleveMat: any = [];
    dataClasseMoy: any = [];
    dataClasseStudent: any = [];
    dataClasseStudentInfo: any = [];

    constructor(private moyenneCtrl: MoyCtrl, private helpe: HelpersService) {
    }

    showMoyCompo(id_classe: any, lib_compo: any) {
        this.moyenneCtrl.getMoyCompo(id_classe, lib_compo)
            .then((data) => {
                this.dataMoyCompo = data.data;
                this.dataMoyCompoFIN = [];
                // if(data.data.statut==false){
                //     this.statutData=tr
                // }
                for (let i = 0; i < data.data.length; i++) {
                    this.dataMoyCompoFIN.push({
                        'matricule': data.data[i].matricule,
                        'eleve': data.data[i].nom + ' ' + data.data[i].nom,
                        'coef': parseFloat(data.data[i].coef),
                        'moys': parseFloat(data.data[i].moys),
                    });
                }
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    showClasse(id_niveau?: string) {
        this.helpe.getClasseScole(id_niveau)
            .then((data) => {
                this.dataClasse = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    showStudentClasse(id_classe?: string) {
        this.moyenneCtrl.getStudentClasse(id_classe)
            .then((data) => {
                data.statut ? this.dataClasseStudent = data.data : this.dataClasseStudent = [];
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    showStudent(id_classe?: string) {
        this.moyenneCtrl.getStudentClasse(id_classe)
            .then((data) => {
                data.statut ? this.dataClasseStudentInfo = data.data : this.dataClasseStudentInfo = [];
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    showMoyCompoByClasse(id_classe: any, lib_compo: any) {
        this.moyenneCtrl.getMoyCompoByclasse(id_classe, lib_compo)
            .then((data) => {
                this.dataClasseMoy = [];

                this.dataClasseMoy = data.data.sort(function (a, b) {
                    return a.value - b.value;
                });
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    showListMoyCompo(id_classe: any, lib_compo: any) {
        this.moyenneCtrl.getMoyCompo(id_classe, lib_compo)
            .then((data) => {
                this.ListdataMoyCompo = data.data;
                this.dataLisMat = [];
                this.dataLisEleveMat = [];
                /*
                 reformatge de la liste des matieres
                 */
                for (let i = 0; i < data.data.length; i++) {
                    if (this.dataLisMat.length != 0) {
                        let p: any = 0;
                        for (let k = 0; k < this.dataLisMat.length; k++) {
                            if (data.data[i].id_matiere == this.dataLisMat[k].id_matiere) {
                                p = 1;
                            }
                        }
                        if (p == 0) {
                            this.dataLisMat.push({
                                'id_matiere': data.data[i].id_matiere,
                                'lib_matiere': data.data[i].lib_matiere,
                            });
                        }
                    } else {
                        this.dataLisMat.push({
                            'id_matiere': data.data[i].id_matiere,
                            'lib_matiere': data.data[i].lib_matiere,
                        });
                    }
                }
                /*
                 Reformatage de la liste des eleves
                 */
                for (let i = 0; i < data.data.length; i++) {
                    if (this.dataLisEleveMat.length != 0) {
                        let p: any = 0;
                        for (let k = 0; k < this.dataLisEleveMat.length; k++) {
                            if (data.data[i].matricule == this.dataLisEleveMat[k].matricule) {
                                p = 1;
                            }
                        }
                        if (p == 0) {
                            this.dataLisEleveMat.push({
                                'eleve': data.data[i].nom + ' ' + data.data[i].prenom,
                                'matricule': data.data[i].matricule,
                            });
                        }
                    } else {
                        this.dataLisEleveMat.push({
                            'eleve': data.data[i].nom + ' ' + data.data[i].prenom,
                            'matricule': data.data[i].matricule,
                        });
                    }
                }
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    addMoyCompo(data: any) {
        this.moyenneCtrl.createMoyenneCompo(data)
            .then((data) => {

            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }
}
