import { Component, Input, OnInit } from '@angular/core';
import { HelpersService } from 'src/app/helper/helper.service';
import { _SERVEROOT } from 'src/app/routes/server-root';
import { GestionService } from 'src/app/service/function/gestion.service';
import { ICarouselItem } from './interface.carousel';
declare let $: any;
@Component({
    selector: 'app-accueil',
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {

    /**
     * Custom Properties
     */
    @Input() height = 500;
    @Input() isFullScreen = false;
    @Input() items: ICarouselItem[] = [
        {
            id: 1,
            title: {
                first: 'USP',
                second: 'Abidjan'
            },
            subtitle: 'Université saint paul, bienvenue !',
            link: '/',
            image: 'assets/img/carousel/1_3.jpg'
        },
        {
            id: 2,
            title: {
                first: 'GESSP',
                second: 'Abidjan - Lakota'
            },
            subtitle: 'Groupe d\'Enseignement Superieur et Professionnel d\'Adgbé ',
            link: '/',
            image: 'assets/img/carousel/1_2.jpg'
        },
        {
            id: 3,
            title: {
                first: 'IFOM',
                second: 'Abidjan'
            },
            subtitle: 'Institut de Formation des Ouvriers de la Moisson',
            link: '/',
            image: 'assets/img/carousel/3.jpg'
        },
        {
            id: 4,
            title: {
                first: 'GSA',
                second: 'Lakota'
            },
            subtitle: 'Groupe Scolaire Agbé',
            link: '/',
            image: 'assets/img/carousel/4.jpg'
        }
    ];

    /**
     * Final Properties
     */
    public finalHeight: string | number = 0;
    public currentPosition = 0;
    raisSoc: string = "UNIVERSITE SAINT PAUL";

    table_annee_academique_master: any = [
        {
            libelle: 'Kit',
            l1: 50000,
            l2: 50000,
        },
        {
            libelle: 'Frais d\'inscription',
            l1: 225000,
            l2: 225000,
        },
        {
            libelle: '1er versement',
            l1: 212500,
            l2: 212500,
        }
        ,
        {
            libelle: '2ème versement',
            l1: 212500,
            l2: 212500,
        }, {
            libelle: 'Scolarité',
            l1: 425000,
            l2: 425000,
        },
        {
            libelle: 'Frais annexe',
            l1: 0,
            l2: 0,
        },
        {
            libelle: 'CS PP 0%',
            l1: -0,
            l2: -0,
        },
        {
            libelle: 'Total à payer',
            l1: 700000,
            l2: 700000,
        }
    ]

    table_annee_academique: any = [
        {
            libelle: 'Kit',
            l1: 50000,
            l2: 50000,
            l3: 50000
        },
        {
            libelle: 'Frais d\'inscription',
            l1: 225000,
            l2: 225000,
            l3: 225000
        },
        {
            libelle: '1er versement',
            l1: 125000,
            l2: 125000,
            l3: 187500
        }
        ,
        {
            libelle: '2ème versement',
            l1: 125000,
            l2: 125000,
            l3: 187500
        }, {
            libelle: 'Scolarité',
            l1: 250000,
            l2: 250000,
            l3: 370000
        },
        {
            libelle: 'Frais annexe',
            l1: 0,
            l2: 0,
            l3: 0
        },
        {
            libelle: 'CS PP 0%',
            l1: -0,
            l2: -0,
            l3: -0
        },
        {
            libelle: 'Total à payer',
            l1: 525000,
            l2: 525000,
            l3: 650000
        }
    ]

    Images = [
        'assets/img/hero-slider/3_1.jpg',//s1
        'assets/img/hero-slider/3_2.jpg',//2
        'assets/img/hero-slider/s2.jpg',
        'assets/img/hero-slider/s3.jpg'
    ];

    ImagesCalendrier = [
        {
            image: 'assets/img/usp/1.jpg',
            TitleImage: 'Formation 2023-2024',
            Titleevent: 'Rentrée scolaire',
            textImage: ' un enseignement de niveau universitaire de qualité tissé avec des normes académiques.'
        },
        {
            image: 'assets/img/usp/2.jpg',
            TitleImage: 'Rentrée scolaire 2023-2024',
            Titleevent: 'En salle de formation',
            textImage: 'un enseignement de niveau universitaire de qualité tissé avec des normes académiques.'
        },
        {
            image: 'assets/img/usp/3.jpg',
            TitleImage: "La cours de l'école",
            Titleevent: "La cours de l'école",
            textImage: 'un enseignement de niveau universitaire de qualité tissé avec des normes académiques.'
        }
    ];
    cursusUsp = [
        {
            id: 1,
            Titre: 'université',
        },
        {
            id: 2,
            Titre: 'collège',

        },
        {
            id: 3,
            Titre: 'primaire',

        }, {
            id: 4,
            Titre: 'maternelle',
        }
    ];
    programmeUsp = [
        // {
        //     Titre: 'Année préparatoire',
        //     texteProg: ' un enseignement de niveau universitaire de qualité tissé avec des normes académiques.',
        //     view: "Prepa"
        // },
        {
            Titre: 'Licence',
            texteProg: ' un enseignement de niveau universitaire de qualité tissé avec des normes académiques.',
            view: "Licence"
        },
        {
            Titre: 'Master',
            texteProg: ' un enseignement de niveau universitaire de qualité tissé avec des normes académiques.',
            view: "Master"
        }
    ];
    SlideOptions = {
        items: 1,
        dots: false,
        mouseDrag: false,
        touchDrag: true,
        stopOnHover: true,
        nav: false,
        responsiveClass: true,
        autoplay: true,
        autoplayTimeout: 7000,
        loop: true,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
    };
    CarouselOptions = {
        items: 3,
        dots: false,
        nav: true,
        responsiveClass: true,
        navSpeed: 700,

    };


    ///notre université
    imgNotreUniversite = [
        {
            image: 'assets/img/activites_usp_gessp/couloirs_usp_gessp/DSC01025.JPG',
            thumbImage: 'assets/img/activites_usp_gessp/couloirs_usp_gessp/DSC01025.JPG',
            title: 'Université saint paul'
        },
        {
            image: 'assets/img/activites_usp_gessp/cours_droit/DSC01056.JPG',
            thumbImage: 'assets/img/activites_usp_gessp/cours_droit/DSC01056.JPG',
            title: 'Cours de droit'
        },
        {
            image: 'assets/img/activites_usp_gessp/entree_salle_cours/DSC01051.JPG',
            thumbImage: 'assets/img/activites_usp_gessp/entree_salle_cours/DSC01051.JPG',
            title: 'Entrée en salle USP',
        },
        {
            image: 'assets/img/activites_usp_gessp/preseminaire_recherche/DSC01131.JPG',
            thumbImage: 'assets/img/activites_usp_gessp/preseminaire_recherche/DSC01131.JPG',
            title: 'Pré-séminaire de recherche'
        },
        {
            image: 'assets/img/activites_usp_gessp/seance_professeurs_usp_gessp_platforme/DSC01070.JPG',
            thumbImage: 'assets/img/activites_usp_gessp/seance_professeurs_usp_gessp_platforme/DSC01070.JPG',
            title: 'Séance de travail avec les professeurs'
        },
        {
            image: 'assets/img/activites_usp_gessp/seminaire_de_droit_usp/DSC01155.JPG',//video: 'assets/img/1/n6.jpg',
            thumbImage: 'assets/img/activites_usp_gessp/seminaire_de_droit_usp/DSC01155.JPG', 
            alt: 'Séminaire de recherche organisé par la facuté de droit de l\'usp'
        },
        {
            image: 'assets/img/activites_usp_gessp/seminaire_de_droit_usp/DSC01169.JPG',//video: 'assets/img/1/n6.jpg',
            thumbImage: 'assets/img/activites_usp_gessp/seminaire_de_droit_usp/DSC01169.JPG', 
            alt: 'Séminaire de recherche organisé par la facuté de droit de l\'usp'
        },
        // {
        //     image: 'assets/img/activites_usp_gessp/seminaire_de_droit_usp/DSC01155.JPG',//video: 'assets/img/1/n6.jpg',
        //     posterImage: 'assets/img/activites_usp_gessp/seminaire_de_droit_usp/DSC01155.JPG', 
        //     alt: 'Séminaire de recherche organisé par la facuté de droit de l\'usp'
        // }
    ];

    constructor(public helpe: HelpersService, public gestion: GestionService) {
        this.finalHeight = this.isFullScreen ? '100vh' : `${this.height}px`;
    }

    ngOnInit() {
        this.items.map((i, index) => {
            i.id = index;
            i.marginLeft = 0;
        });
        this.animate();
        // this.helpe.showListEcole();
        this.gestion.searchClasse("", "123069");
    }

    voirSuite(event) {

        switch (event) {
            case "fondateur":
                $("#motD").hide();
                $("#motF").show();
                break;
            case "directeur":
                $("#motD").show();
                $("#motF").hide();
                break;
            default:

                break;
        }
    }

    menuSelect(event: any, code_ecole: any) {
        this.helpe.codeEtabSelect = code_ecole;
        this.gestion.dataClasse = [];
        $("#spLoadViw").hide();
        $("#spLoad").show();
        $("#Prepa").hide();
        $("#Licence").hide();
        $("#Master").hide();
        $("#tableInsc").hide();
        $(".Cout").hide();
        switch (event) {
            case 1:
                this.raisSoc = "UNIVERSITE SAINT PAUL";
                this.gestion.searchClasse("", code_ecole);
                $("#menu1").css('background-color', '#e30513');
                $("#menu1 a").css('color', '#fff');
                $("#menu1").css('border', '#fff');

                $("#menu2").css('background-color', '#fff');
                $("#menu2 a").css('color', '#000');
                $("#menu2").css('border', '1px solid black');

                $("#menu3").css('background-color', '#fff');
                $("#menu3 a").css('color', '#000');
                $("#menu3").css('border', '1px solid black');

                $("#menu4").css('background-color', '#fff');
                $("#menu4 a").css('color', '#000');
                $("#menu4").css('border', '1px solid black');


                $("#content1").show();
                $("#content2").hide();
                $("#content3").hide();
                $("#content4").hide();
                break;
            case 2:
                this.gestion.searchClasse("", code_ecole);
                this.raisSoc = "GROUPE D’ENSEIGNEMENT SUPERIEUR ET PROFESSIONNEL D’AGBE";
                $("#menu2").css('background-color', '#e30513');
                $("#menu2 a").css('color', '#fff');
                $("#menu2").css('border', '#fff');

                $("#menu1").css('background-color', '#fff');
                $("#menu1 a").css('color', '#000');
                $("#menu1").css('border', '1px solid black');

                $("#menu3").css('background-color', '#fff');
                $("#menu3 a").css('color', '#000');
                $("#menu3").css('border', '1px solid black');

                $("#menu4").css('background-color', '#fff');
                $("#menu4 a").css('color', '#000');
                $("#menu4").css('border', '1px solid black');

                $("#content1").hide();
                $("#content2").show();
                $("#content3").hide();
                $("#content4").hide();
                break;
            case 3:
                this.gestion.searchClasse("", code_ecole);
                this.raisSoc = "COLLEGE PRIVE AGBE DE LAKOTA";
                $("#menu1").css('background-color', '#fff');
                $("#menu1 a").css('color', '#000');
                $("#menu1").css('border', '1px solid black');

                $("#menu3").css('background-color', '#e30513');
                $("#menu3 a").css('color', '#fff');
                $("#menu3").css('border', '#fff');

                $("#menu2").css('background-color', '#fff');
                $("#menu2 a").css('color', '#000');
                $("#menu2").css('border', '1px solid black');

                $("#menu4").css('background-color', '#fff');
                $("#menu4 a").css('color', '#000');
                $("#menu4").css('border', '1px solid black');


                $("#content1").hide();
                $("#content2").hide();
                $("#content3").show();
                $("#content4").hide();
                break;
            case 4:
                this.gestion.searchClasse("", code_ecole);
                this.raisSoc = "INSTITUT DE FORMATION DES OUVRIERS DE LA MOISSON";
                $("#menu1").css('background-color', '#fff');
                $("#menu1 a").css('color', '#000');
                $("#menu1").css('border', '1px solid black');

                $("#menu2").css('background-color', '#fff');
                $("#menu2 a").css('color', '#000');
                $("#menu2").css('border', '1px solid black');

                $("#menu3").css('background-color', '#fff');
                $("#menu3 a").css('color', '#000');
                $("#menu3").css('border', '1px solid black');

                $("#menu4").css('background-color', '#e30513');
                $("#menu4 a").css('color', '#fff');
                $("#menu4").css('border', '#fff');

                $("#content1").hide();
                $("#content2").hide();
                $("#content3").hide();
                $("#content4").show();
                break;
            default:

                break;
        }
        setTimeout(() => {
            $("#spLoad").hide();
            $("#spLoadViw").show();
        }, 2000);
    }
    downloadFile(param) {
        switch (param) {
            case "Licence":
                window.open("http://usp-api.e2t.ci/upload/dossiers/Présentation de GESSP.pdf", '_blank');
                break;
            case "Master":
                window.open("http://usp-api.e2t.ci/upload/dossiers/Présentation de GESSP Master.pdf", '_blank');

                break;

            default:
                break;
        }

    }
    GuiddeUse() {
        window.open("http://usp-api.e2t.ci/upload/dossiers/Guide d'étudiant USP - pour fusion.pdf", '_blank');
    }
    viewPage(event) {
        switch (event) {
            case "Ggesp":
                this.helpe.logoGet = "gessp"
                // window.location.href = "#/groupe-enseig-superieur-agbe";
                window.location.href = "http://www.gessp.universitesaintpaul.org/";
                break;
            case "Gsa":
                this.helpe.logoGet = "gsa"
                // window.location.href = "#/groupe-scolaire-Agbe";
                window.location.href = "http://www.gsa.universitesaintpaul.org/";
                break;
            case "usp":
                this.helpe.logoGet = "usp"
                window.location.href = "http://www.universitesaintpaul.org/";
                break;
            case "ifom":
                this.helpe.logoGet = "ifom"
                //  window.location.href = "#/ifom";
                window.location.href = "http://www.ifom.universitesaintpaul.org/";
                break;
            default:
                break;
        }
    }
    ViewDocFile(param) {
        $("#Prepa").hide();
        $("#Licence").hide();
        $("#Master").hide();
        $("#Cout_licence").hide();
        $("#Cout_master").hide();
        switch (param) {
            case "Prepa":

                $("#Licence").hide()
                $("#Master").hide()
                $(".Cout_licence").hide();
                $(".Cout_master").hide();
                $("#jeTourne").show()
                setTimeout(() => {
                    $("#Prepa").show()
                    $(".Cout").show()
                    $("#jeTourne").hide()
                }, 3000);
                break;
            case "Licence":
                $("#Master").hide()
                $("#Cout_master").hide();
                $("#jeTourne").show()
                setTimeout(() => {
                    $("#jeTourne").hide()
                    $("#Cout_licence").show()
                    $("#Prepa").show()
                    $("#Licence").show()
                }, 3000);
                break;
            case "Master":
                console.log("master")
                $("#Licence").hide()
                $("#jeTourne").show()
                $("#Cout_licence").hide();
                setTimeout(() => {
                    $("#Cout_master").show()
                    $("#Prepa").show()
                    $("#Master").show()
                    $("#jeTourne").hide()
                }, 3000);
                break;
            default:
                break;
        }
    }

    // a supprimer 


    setCurrentPosition(position: number) {
        this.currentPosition = position;
        this.items.find(i => i.id === 0).marginLeft = -100 * position;
    }

    setNext() {
        let finalPercentage = 0;
        let nextPosition = this.currentPosition + 1;
        if (nextPosition <= this.items.length - 1) {
            finalPercentage = -100 * nextPosition;
        } else {
            nextPosition = 0;
        }
        this.items.find(i => i.id === 0).marginLeft = finalPercentage;
        this.currentPosition = nextPosition;
    }

    setBack() {
        let finalPercentage = 0;
        let backPosition = this.currentPosition - 1;
        if (backPosition >= 0) {
            finalPercentage = -100 * backPosition;
        } else {
            backPosition = this.items.length - 1;
            finalPercentage = -100 * backPosition;
        }
        this.items.find(i => i.id === 0).marginLeft = finalPercentage;
        this.currentPosition = backPosition;

    }

    animate() {
        let i = 0;
        setInterval(function () {
            i++;
            $('.dsc-carousel__content-item').find('span:nth-child(' + i + ')').show();
            if (i == 9) {
                $('.dsc-carousel__content-item').find('span:nth-child(1)').css({
                    'font-style': 'italic',
                    'transition': 'all 0.5s ease-out'
                });
            }

            if (i == 11) {
                $('.dsc-carousel__content-item').find('span').css({
                    'letter-spacing': '30px'
                });
            }

            if (i == 13) {
                $('.dsc-carousel__content-item').find('span').css({
                    'letter-spacing': '1px',
                    'font-style': 'normal'
                });
            }
            if (i == 14) {
                i = 1;
            }
        }, 800);
    }



    caracteristiques(params) {

        $("#cTourne").show();
        $("#objectifs").hide();
        $("#sectionView").hide();
        $("#avantages").hide();
        $("#mission").hide();

        switch (params) {

            case "mission":
                setTimeout(() => {
                    $("#cTourne").hide();
                    $("#sectionView").show();
                    $("#mission").show();
                }, 4000);
                break;
            case "objectifs":
                setTimeout(() => {
                    $("#cTourne").hide();
                    $("#sectionView").show();
                    $("#objectifs").show();
                }, 4000);
                break;
            case "formations":
                setTimeout(() => {
                    window.location.href = "#/facultes";
                }, 4000);
                break;
            case "avantages":
                setTimeout(() => {
                    $("#cTourne").hide();
                    $("#sectionView").show();
                    $("#avantages").show();
                }, 4000);
            default:
                break;
        }

    }

    onloadViewPage(e: any) {
        // routerLink="/facultes/{{item?.id_niveau}}"
        localStorage.clear();
        localStorage.setItem("param_cons", JSON.stringify(e));

        window.location.href = "#/consultation/";
        // helpe.codeEtabSelect=item?.code_ecole;helpe.LibFilierSelect=item?.lib_classe
    }

}
