import { ClasseModel } from './../models/classeModel.service';
import { NiveauModel } from './../models/niveauModel.service';
import { CategorieModel } from './../models/categorieModel.service';
import { CycleModel } from './../models/cycleModel.service';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { _SERVEROOT, AUTHORIZATION } from '../../routes/server-root';
import { TypematiereModel } from '../models/typematiereModel.service';
import { MatiereModel } from '../models/matiereModel.service';
import { CompoModel } from '../models/compoModel.service';
declare let Promise: any;

@Injectable()
export class GestionCtrl {
    constructor(public http: HttpClient) {
    }


    /*************************
     *
     *  Toutes les methodes pour la categorie
     *
     *************************
     */

    createCatCompo(data: CategorieModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                lib_catcompo: data.lib_cat
            }
                ;
            this.http.post(_SERVEROOT.addCatCompo, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getCatCompo() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.getCatCompo + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
    getCompo() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listCompo + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    /*************************
     *
     *  Toutes les methodes pour le cycle
     *
     *************************
     */

    createCycle(data) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                lib_cycle: data.lib_cycle,
                id_cat_composition: data.id_cat_composition
            }
                ;
            this.http.post(_SERVEROOT.addCycle,
                insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getCycle() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.getCycle + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
    getAllCycle(code_ecole: any) {
        const headers = new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Access-Control-Allow-Headers', 'Content-Type')
        .append('Access-Control-Allow-Methods', 'GET')
        .append('Access-Control-Allow-Origin', '*');
        // AUTHORIZATION.token
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.getCycle + "?code_ecole=" + code_ecole, {headers})
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
    getTest() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT._test, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    /*************************
     *
     *  Toutes les methodes pour le niveau
     *
     *************************
     */

    createNiveau(data: NiveauModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                id_cycle: data.id_cycle,
                lib_niveau: data.lib_niveau
            }
                ;
            this.http.post(_SERVEROOT.addNiveau, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getNiveau() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    /*************************
     *
     *  Toutes les methodes pour les classes
     *
     *************************
     */

    createClasse(data: ClasseModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                id_niveau: data.id_niveau,
                lib_classe: data.lib_classe
            }
                ;
            this.http.post(_SERVEROOT.addClasse, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getClasse() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    /*************************
     *
     *  Toutes les methodes pour les matieres
     *
     *************************
     */

    createTypeMatiere(data: TypematiereModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                lib_typmatiere: data.lib_typmatiere,
                id_cycle: data.id_cycle
            }
                ;
            this.http.post(_SERVEROOT.addTypeMatiere, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getTypeMatiere() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.getAllTypeMatter + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    /*************************
     *
     *  Toutes les methodes pour les matieres
     *
     *************************
     */

    createMatiere(data: MatiereModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                lib_matiere: data.lib_matiere,
                id_type_mat: data.id_type_mat
            }
                ;
            this.http.post(_SERVEROOT.addMatiere, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getMatiere() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    /*************************
     *
     *  Toutes les methodes pour les matieres
     *
     *************************
     */

    createCompo(data: CompoModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                lib_compo: data.lib_compo,
                id_catcompo: data.id_catcompo
            }
                ;
            this.http.post(_SERVEROOT.addComposition, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    /*************************
     *
     *  Toutes les methodes de recherche
     *
     *************************
     */
    searchNiveau(id_cycle: string, code_ecole: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.searchNiveauByCylce + "?code_ecole=" + code_ecole + "&id_cycle=" + id_cycle, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
    searchNiveauParam(code_ecole: string, id_cycle: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.searchNiveauByCylce + "?code_ecole=" + code_ecole + "&id_cycle=" + id_cycle, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
    searchClasse(id_niveau?: string, code_ecole?: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.searchClasseByNiveau + "?code_ecole=" + code_ecole + "&id_niveau=" + id_niveau, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    seach_Matters(stringsearch?: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.seach_MattersByTypeMatters + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&lib_typmatiere=" + stringsearch, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
    getListeNoteByProf(idClasse: any, idmatiere: any, idcompo: any) {
        return new Promise((resolve, reject) => {//
            this.http.get(_SERVEROOT.ListNote + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&annee=" + AUTHORIZATION.ecoParam.idannee + "&id_classe=" + idClasse + "&code_user=" + AUTHORIZATION.ecoParam.code_user + "&lib_mat=" + idmatiere + "&lib_compo=" + idcompo, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
    getEtabBySite(id_site: any) {
        return new Promise((resolve, reject) => {//
            this.http.get(_SERVEROOT.ListNote + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&id_classe=" + id_site, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
    getScolarite(code_ecole: any,annee:any,id_niveau:any) {
        return new Promise((resolve, reject) => {//
            this.http.get(_SERVEROOT.getScolarite + "?code_ecole=" + code_ecole + "&annee=" + annee+"&id_niveau="+id_niveau, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    getModalite(code_ecole?: any,annee?:any,id_niveau?:any,echeance?:any) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listeModalite + "?code_ecole=" + code_ecole + "&annee=" + annee+"&id_niveau="+id_niveau+"&echeance="+echeance, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
}