import {AUTHORIZATION, _SERVEROOT} from '../../routes/server-root';
import {Injectable, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CookiesService} from "../../cookies/cookies.service";
import {EcoleModel} from '../models/ecoleModel.service';


declare let $: any;
declare let Promise: any;

@Injectable()
export class ecoleCtrl implements OnInit {

    constructor(public cookies: CookiesService, private http: HttpClient) {
    }

    ngOnInit() {
    }

    UpdatEtbs(data) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                code_user: AUTHORIZATION.ecoParam.code_user,
                raisonsociale: data.raisonsociale,
                email: data.email,
                contact: data.contact,
                adresse: data.adresse,
                fondateur: data.fondateur,
                directeur: data.directeur,
                site_geo: data.site_geo,
                logo: data.logo,
                credo: data.credo,
                statut: data.statut
            };
            this.http.post(_SERVEROOT.updateEtabs, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );

        });
    }

    getDataEtbcs() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listOneEstab + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&annee=" + AUTHORIZATION.ecoParam.idannee, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getDataAllEtbcs(paramEtab?: any) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listEstablishment + "?code_ecole=" + paramEtab, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
}