import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $('.parallax-window').parallax({ imageSrc: '../../../assets/img/carousel/1.jpg' });
  }
  downloadFile() {
    window.open("http://usp-api.e2t.ci/upload/dossiers/Guide d'étudiant USP - pour fusion.pdf", '_blank');
  }
  caracteristiques(params) {

    $("#cTourne").show();
    $("#objectifs").hide();
    $("#sectionView").hide();
    $("#avantages").hide();
    $("#mission").hide();

    switch (params) {

      case "mission":
        setTimeout(() => {
          $("#cTourne").hide();
          $("#sectionView").show();
          $("#mission").show();
        }, 4000);
        break;
      case "objectifs":
        setTimeout(() => {
          $("#cTourne").hide();
          $("#sectionView").show();
          $("#objectifs").show();
        }, 4000);
        break;
      case "formations":
        setTimeout(() => {
          window.location.href = "#/facultes";
        }, 4000);
        break;
      case "avantages":
        setTimeout(() => {
          $("#cTourne").hide();
          $("#sectionView").show();
          $("#avantages").show();
        }, 4000);
      default:
        break;
    }

  }
  animate() {
    let i = 0;
    setInterval(function () {
      i++;
      $('.anime').find('span:nth-child(' + i + ')').show();
      if (i == 9) {
        $('.anime').find('span:nth-child(1)').css({
          'font-style': 'italic',
          'transition': 'all 0.5s ease-out'
        });
      }

      if (i == 11) {
        $('.anime').find('span').css({
          'letter-spacing': '30px'
        });
      }

      if (i == 13) {
        $('.anime').find('span').css({
          'letter-spacing': '1px',
          'font-style': 'normal'
        });
      }
      if (i == 14) {
        i = 1;
      }
    }, 800);
  }
  pre_inscrption() {
    window.location.href = "http://universitesaintpaul.org/#/pre-inscription";
  }
}
