import { Injectable } from '@angular/core';

@Injectable()
export class UsersModel {

  constructor() { }
  public nom:string;
  public prenom:string;
  public fonction:string;
  public role:string;
  public contact:string;
  public date:string;
  public sexe:string;
  public localite:string;
  public user_login:string;
  public type_user:any;
  public route:string;

}