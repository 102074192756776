import { CookieService } from 'ngx-cookie-service';
import { CookiesService } from './../../cookies/cookies.service';
import { Component, OnInit } from '@angular/core';
import { HelpersService } from 'src/app/helper/helper.service';
let serve = require('src/assets/data/serve.json');
declare let $: any;
@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {
    nomcomplet: any;
    nom_etab: string = "Dev & Inova";
    constructor(private cookieService: CookieService, public cookiesService: CookiesService, public helpe: HelpersService) {
    }

    ngOnInit() {
        let getCookies = this.cookieService.get('lasource');
        if (getCookies) {
            let jsp: any = JSON.parse(getCookies);
            this.nomcomplet = jsp.nom_prenom;
            // this.nom_etab = jsp.libelle_ecole.substr(0,10);
        }

        $('.nav-switch').on('click', function (event) {
            $('.main-menu').slideToggle(400);
            event.preventDefault();
        });
    }

    logout() {
        this.cookieService.delete('lasource');
        this.cookieService.delete('lasourceConfig');
        window.location.href = "http://ecole.e2t.ci";
    }
    toTbord() {
        window.location.href = serve.tb;
    }
}
