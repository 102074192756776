import { Injectable } from '@angular/core';

@Injectable()
export class ScolariteModel {

  constructor() { }
  public lib_niveau:string;
  public idlib_niveau:string;
  public scolarite:number;
  public idscolarite:string;
}