import { Injectable } from '@angular/core';

@Injectable()
export class AttrClasseModel {

  constructor() { }
  public login_user:string;
  public pp:string;
  public id_classe:any={};
  public matiere:string;
}