import {Component, OnInit} from '@angular/core';
import {ElevesService} from '../../../service/function/eleves.service';
import {EleveModel} from '../../../database/models/eleveModel.service';
import {NgForm} from '@angular/forms';
import {HelpersService} from '../../../helper/helper.service';
import {ToastrService} from 'ngx-toastr';
import {EleveCtrl} from '../../../database/controllers/eleveCtrl.service';
declare let $: any;
@Component({
    selector: 'updatestudent-cmp',
    templateUrl: './updatestudent.component.html',
    styleUrls: ['./updatestudent.component.css']
})
export class UpdatestudentComponent implements OnInit {
    upDateSubmitted: boolean;
    imageUrl: string = "/assets/img/Blank_Avatar.png";
    fileToUpload: File = null;
    nation: any;
    countPhone: any;
    countermatricul: number = 0;
    countPhoneparent: number = 0;
    genre: any[] = ["Masculin", "Féminin"];

    constructor(public elevesService: ElevesService,
                public ecoleModel: EleveModel,
                public helpe: HelpersService,
                private toastr: ToastrService,
                private eveleCtrl: EleveCtrl) {
    }

    ngOnInit() {
        $('select').select2();
        $(".select2-list").select2({
            allowClear: true
        });

        $('#datenaiss1').datepicker({autoclose: true, todayHighlight: true, format: "dd/mm/yyyy"});
        $('#demo-date-month').datepicker({autoclose: true, todayHighlight: true, minViewMode: 1});
        $('#demo-date-format').datepicker({autoclose: true, todayHighlight: true, format: "yyyy/mm/dd"});
        $('#demo-date-range').datepicker({todayHighlight: true});
        $('#demo-date-inline').datepicker({todayHighlight: true});

        this.countPhone = this.elevesService.infoEleve.contact.length;
        this.countermatricul = this.elevesService.infoEleve.matricule.length;
        this.countPhoneparent = this.elevesService.infoEleve.code_parent.length;

    }

    handFile(file: FileList) {//

        //this.selectedtFil = <File>event.target.files[0];

        this.fileToUpload = file.item(0);
        let reader = new FileReader()
        reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
        }

        reader.readAsDataURL(this.fileToUpload);
    }

    updateEleve(form: NgForm) {


        /*
         *recuperation des données via jQuery
         */
        this.elevesService.infoEleve.sexe = $("select[name='sexe1'] option:selected").val();//.str(0,1);
        this.elevesService.infoEleve.nationalite = $("select[name='nation1'] option:selected").val();
        this.elevesService.infoEleve.datenaiss = $("#datenaiss1").val();
        /*
         * fin recuperation jQuery
         */


        this.upDateSubmitted = false;
        if (form.invalid) {
            this.scrollTo();
            return;
        }
        if (this.elevesService.infoEleve.nationalite == undefined || this.elevesService.infoEleve.nationalite == '') {
            return;
        } if (this.elevesService.infoEleve.sexe == undefined || this.elevesService.infoEleve.sexe == '') {
            return;
        } if (this.elevesService.infoEleve.datenaiss == undefined || this.elevesService.infoEleve.datenaiss == '') {
            return;
        }

        $("#sp").show();
        this.upDateSubmitted = true;

        this.eveleCtrl.updateEleves(this.elevesService.infoEleve)
            .then((data) => {
                $("#sp").hide();
                if (data.statut == true) {
                    this.elevesService.getStudent();
                    this.toastr.success(data.message, 'ELEVE');
                    form.resetForm();
                } else {

                    this.toastr.error(data.message, 'ELEVE');
                }
            })
            .catch((err) => {
                console.log(")-,-");
            })

    }

    selectNationality(event) {
        if (event.value) {
            this.ecoleModel.nationalite = event.value[0]
        }
    }

    scrollTo() {
        window.scrollTo(0, 0);
    }

    counter() {
        if (this.elevesService.infoEleve.contact.length <= 8) {
            this.countPhone = this.elevesService.infoEleve.contact.length;
            $(".cter").removeClass('text-danger')
        } else {
            $(".cter").addClass('text-danger');
        }
    }

    countermat() {
        if (this.elevesService.infoEleve.matricule.length <= 9) {
            this.countermatricul = this.elevesService.infoEleve.matricule.length;
            $(".ctermat").removeClass('text-danger')
        } else {
            $(".ctermat").addClass('text-danger');
        }
    }

    counterparent() {
        if (this.elevesService.infoEleve.code_parent.length <= 8) {
            this.countPhoneparent = this.elevesService.infoEleve.code_parent.length;
            $(".cterparent").removeClass('text-danger')
        } else {
            $(".cterparent").addClass('text-danger');
        }
    }
}


