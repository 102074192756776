import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad } from "@angular/router";
import { Observable } from "rxjs";
import { CookiesService } from "../cookies/cookies.service";
import { HttpClient } from "@angular/common/http";
import { _SERVEROOT } from "../routes/server-root";
import { CookieService } from 'ngx-cookie-service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common'; //Location,
declare let $: any;
declare let Promise: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  currentUrl: any = [];
  constructor(public http: HttpClient, protected router: Router, public cookieService: CookieService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.currentUrl=[];
    let u: any = state.url.replace('/', '');
    this.currentUrl.push(u.split('/'));

    if (this.cookieService.get('lasource') && this.cookieService.get('lasourceConfig')) {
      return true;
    }
   // window.location.href = _SERVEROOT.redirectToLog;
    //return undefined;
    return true;
  }
}
