import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CookiesService } from './cookies/cookies.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { SettingService } from './setting/setting.service';
import { AuthService } from './auth/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(public setting:SettingService,
    public uri:AuthService,
    private cookieService: CookieService,
    public cookiesService: CookiesService){}

  ngOnInit() {
     
  }
}
