import { Component, OnInit } from '@angular/core';
import { ElevesService } from '../../../service/function/eleves.service';
import { HelpersService } from '../../../helper/helper.service';
declare let $: any;
@Component({
  selector: 'liststudent-cmp',
  templateUrl: './liststudent.component.html',
  styleUrls: ['./liststudent.component.css']
})
export class ListstudentComponent implements OnInit {
  p: any;
  view: string = ""
  prog: number;
  timeoutId: any;
  constructor(public elevesService: ElevesService, public helpe: HelpersService) { }

  ngOnInit() {
    this.elevesService.getStudent();
  }


  /**
   * 
   */


  seenUpdate() {
    let i = 0;
    this.prog =0;
    this.elevesService.viewUp = 'pgrss';
    this.timeoutId = setInterval(() => {
      i++
      if (i == 1) {
        this.prog = 25;
        $(".prgress").css({
          'width': '25%'
        })
      }
      if (i == 4) {
        this.prog = 50;
        $(".prgress").css({
          'width': '50%'
        })
      }
      if (i == 6) {
        this.prog = 75;
        $(".prgress").css({
          'width': '75%'
        })
      }
      if (i == 8) {
        this.prog = 100;
        $(".prgress").css({
          'width': '100%'
        })
        this.elevesService.viewUp = 'up';
        this.helpe.Cleartime(this.timeoutId);
      }

    }, 800)
  }
}
