import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare let $:any;
@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.css']
})
export class ConsultationComponent implements OnInit {
  faculte: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.faculte = this.route.snapshot.params.id;
    this.viewFaculte(this.faculte);
  }

  viewFaculte(Fac) {
    switch (Fac) {
      case "Ggesp":

        break;
      case "Gsa":

        break;
      case "usp":

        break;
      case "ifom":

        break;
      default:
        break;
    }
  }


}
