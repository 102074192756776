import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { cryptSession, decode64, HelpersService } from "../../helper/helper.service";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
declare let $: any;
declare let _: any;
let createGuest = require('cross-domain-storage/guest');
let createHost = require('cross-domain-storage/host');
var storageHost = createHost([
    {
        origin: 'http://localhost:3000/',
        allowedMethods: ['get', 'set', 'remove'],
    },
    {
        origin: 'http://localhost:2000/',
        allowedMethods: ['get'],
    },
]);

@Component({
    selector: 'app-connexion',
    templateUrl: './connexion.component.html',
    styleUrls: ['./connexion.component.css']
})
export class ConnexionComponent implements OnInit {
    login: any;
    pwd: any;
    connectSubmitted: boolean;
    response: string = '';
    data: any = {
        email: "",
        pwd: "",
    };
    dataRouting: any = {
        config: "localhost",//' localhost   .universitesaintpaul.org
        admin: "http://usp-gestion.universitesaintpaul.org/",//"http://localhost:3000"; //http://usp-gestion.universitesaintpaul.org/
        prof: "http://professeurs.universitesaintpaul.org/", //http://localhost:4001     http://professeurs.universitesaintpaul.org/
        parent: "http://parents.universitesaintpaul.org/",// http://localhost:4002     http://parents.universitesaintpaul.org/
        student: "http://etudiants.universitesaintpaul.org/", // http://localhost:4003    http://etudiants.universitesaintpaul.org/
    };

    constructor(private cookieService: CookieService, public helper: HelpersService, private http: HttpClient) {
    }

    ngOnInit() {
    }

    connect(form: NgForm) {

        this.connectSubmitted = false;
        if (form.invalid) {
            this.response = "Le login/mot de passe sont obligatoire.";
            $("#the-message-error").show();
            return;
        }
        $("#id_loader").show();
        this.connectSubmitted = true;
        let me = this;
        $.ajax({
            url: 'http://usp-api.universitesaintpaul.org/admin/Auth/login',
            type: 'POST',
            dataType: 'JSON',
            data: {
                "email": me.login,
                "pwd": me.pwd
            },
            success: function (data) {

                if (data.statut === false) {
                    $("#the-message-error").show();
                    $("#id_loader").hide();
                    me.response = data.message;
                    return
                }
                if (data.statut == 2100) {
                    $("#id_loader").hide();
                    $("#the-message-error").show();
                    me.response = "Votre login ou mot de passe est invalid !";
                    return
                }
                if (data.route == "") {
                    $("#the-message-error").show();
                    $("#id_loader").hide();
                    me.response = "un problème est survenu (-_-)";
                    return
                }

                if (data.token) {


                    me.showEcole(data)


                } else {
                    $("#the-message-error").show();
                }
            },
            error: function (error) {
                $("#id_loader").hide();
                me.response = "Une erreure est survenue, merci ressayer plutard !";
            }
        })
    }
    showEcole(p_data: any) {
        let d_rturns: any = {};

        this.getDataEtbcs(p_data.code_ecole, p_data.idannee, p_data.token)
            .then((data: any) => {
                if (data.statut) {

                    let bazStorage = createGuest(window.location.href === 'http://localhost:3000/#/mon-portail' ? 'http://localhost:2000/' : 'http://localhost:2000/');

                    let mapData: any = [];
                    mapData = {
                        ...p_data,
                        ecole: data.data
                    }

                    let mapSession = cryptSession(JSON.stringify(mapData), decode64(environment.CONFIG.APP_PASS));
                   // localStorage.setItem(environment.CONFIG.APP_TOKEN_NAME, mapSession)





                    bazStorage.set('foo', 'bar', function (error, data) {
                        // foo is now set to 'bar'
                    });
                    bazStorage.get('fizz', function (error, value) {
                        // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
                        if (error) {

                        } else {
                            //setCrossDomainValue()
                        }
                    });



                    this.postCrossDomainMessage(mapSession);
                    var PERMITTED_DOMAIN = "http://localhost:2000";
                    /**
                     * Receiving message from other domain
                     */
                    window.addEventListener('message', function(event) {
                        if (event.origin === PERMITTED_DOMAIN) {
                            //var msg = JSON.parse(event.data);
                            // var msgKey = Object.keys(msg)[0];
                            if (event.data) {
                                localStorage.setItem("localstorage", event.data);
                            } else {
                                localStorage.removeItem("localstorage");
                            }
                        }
                    });



                    let expiredDate = new Date();
                    expiredDate.setDate(expiredDate.getDate() + 7);

                    console.log(mapSession);

                    this.cookieService.set(environment.CONFIG.APP_TOKEN_NAME, "mapSession", expiredDate, '/', this.dataRouting.config);
                    this.cookieService.set("__bn__", mapSession, expiredDate, '/', this.dataRouting.config);
                    //this.cookieService.set('lasource', mapSession, expiredDate, '/', this.dataRouting.config);
                    // this.cookieService.set('lasource', JSON.stringify(p_data), expiredDate, '/', this.dataRouting.config);
                    // this.cookieService.set('lasourceConfig', JSON.stringify(p_data.config), expiredDate, '/', this.dataRouting.config);
                    //  me.helper.show = 1;
                    return
                    switch (_.toLower(p_data.route)) {
                        case "superviseur":
                            window.location.href = this.dataRouting.admin;
                            break;
                        case "utilisateur":
                            window.location.href = this.dataRouting.admin;
                            break;
                        case "Professeur":
                            window.location.href = this.dataRouting.prof;
                            break;
                        case "eleve":
                            window.location.href = this.dataRouting.student;
                            break;
                        case "student":
                            window.location.href = this.dataRouting.student;
                            break;
                        case "parent"://route: "parent"
                            window.location.href = this.dataRouting.parent;
                            break;
                        default:
                            $("#sp").hide();
                            this.response = "Aucune config n'a été trouvée !";
                            break;
                    }



                }

            })
            .catch((err) => { })
        setTimeout(() => {
            return d_rturns;
        }, 1000);

    }

    getDataEtbcs(code_ecole: string, idannee: string, token: any) {
        return new Promise((resolve, reject) => {
            this.http.get("http://usp-api.universitesaintpaul.org/admin/Register/infoEtablisment" + "?code_ecole=" + code_ecole + "&annee=" + idannee, token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    postCrossDomainMessage(msg) {
        let win: any = document.getElementById('ifr');
        let a: any = win.contentWindow
        a.postMessage(msg, "http://localhost:2000");
    }
}
