import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import 'rxjs/add/operator/do';
import {finalize, tap} from 'rxjs/operators';
import {AUTHORIZATION} from "../../routes/server-root";

declare let $: any;


@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // execution de notre function de chargement
        return next.handle(request).do((event: HttpEvent<any>) => {
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                this.ErrorDetect(err);
            }
        });
    }

    ErrorDetect(error: HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
                // detection des erreur de connexion internet depuis le frontEnd
                console.error('An error occurred: un problème de connexion a été detecté');//, error.error.status
            } else {
                // Le backEnd retourne une erreur | detection du status.
                console.error(
                    `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`);
            }
        }
    }
}