import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {_SERVEROOT, AUTHORIZATION} from '../../routes/server-root';
import {addInscriptionModel} from '../models/addInscriptionModel.service';
import {addVersementModel} from '../models/addVerementmodel.service';


declare let $: any;
declare let Promise: any;

@Injectable()
export class CaisseCtrl {
    constructor(public http: HttpClient) {
    }


    /*************************
     *
     *  Toutes les methodes pour la categorie
     *
     *************************
     */

    createInscription(data: addInscriptionModel) {

        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    annee: AUTHORIZATION.ecoParam.idannee,
                    id_niveau: data.lib_niveau,
                    montant: data.montant,
                    id_classe: data.lib_classe,
                    matricule: data.matricule,
                    reduction: data.reduction,
                    code_user: AUTHORIZATION.ecoParam.code_user,
                }
                ;
            this.http.post(_SERVEROOT.inscription, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    createVersement(data: addVersementModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    annee: AUTHORIZATION.ecoParam.idannee,
                    montant: data.montant,
                    id_classe: data.id_classe,
                    matricule: data.matricule,
                    reduction: data.reduction,
                    code_user: AUTHORIZATION.ecoParam.code_user,
                }
                ;
            this.http.post(_SERVEROOT.versement, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    PrintRecuInscription(matricule: any) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    annee: AUTHORIZATION.ecoParam.annee,
                    matricule: matricule,
                }
                ;
            this.http.post(_SERVEROOT.recuins, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getScolarite(lib_niveau: string) {
        return new Promise((resolve, reject) => {

            this.http.get(_SERVEROOT.getScolarite + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&annee=" + AUTHORIZATION.ecoParam.idannee + "&id_niveau=" + lib_niveau, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    getInscritToday(lib_classe?: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listInscris_day + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&annee=" + AUTHORIZATION.ecoParam.idannee + "&lib_classe=" + lib_classe, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    /**
     * liste des inscrirts de l'année
     * @param lib_classe
     */
    list_inscrit(lib_classe?: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.list_inscrit + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&annee=" + AUTHORIZATION.ecoParam.idannee + "&lib_classe=" + lib_classe, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    payment_day(lib_classe?: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.payment_day + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&lib_classe=" + lib_classe + "&annee=" + AUTHORIZATION.ecoParam.idannee, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    list_payment(lib_classe?: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.list_payment + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&lib_classe=" + lib_classe + "&annee=" + AUTHORIZATION.ecoParam.idannee, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }

    remains_payBy_Student(matricule: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.list_payment + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&matricule=" + matricule + "&annee=" + AUTHORIZATION.ecoParam.annee, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
}