import { Injectable } from '@angular/core';
import { EleveCtrl } from '../../database/controllers/eleveCtrl.service';

@Injectable()
export class ElevesService {
  dataStudent: any[];
  infoEleve: any = [];
  dataToeleve: any = [];
  dataToeleveNonInscrit: any = [];
  dataToeleveNonIns: any = [];
  viewUp:string='list';
  constructor(public eleveCtrl: EleveCtrl) { }

  getStudent() {
    this.eleveCtrl.getEleves()
      .then((data) => {
        this.dataToeleve = [];
        for (let i = 0; i < data.data.length; i++) {
          this.dataToeleve.push({
            'id': data.data[i].matricule,
            'name': data.data[i].nom_eleve + " " + data.data[i].prenom_e
          })
        }
        this.dataStudent = data.data;
      })
      .catch((err) => {
        console.log("Un probleme est survenu, merci de réessayer.");
      })
  }
  getStudentnonInscrit() {
    this.eleveCtrl.getElevesNonInscrit()
      .then((data) => {
        this.dataToeleveNonInscrit = [];
        for (let i = 0; i < data.data.length; i++) {
          this.dataToeleveNonInscrit.push({
            'id': data.data[i].matricule,
            'name': data.data[i].nom_eleve + " " + data.data[i].prenom_e
          })
        }
        this.dataToeleveNonIns = data.data;
      })
      .catch((err) => {
        console.log("Un probleme est survenu, merci de réessayer.");
      })
  }
}
