import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor() {
  }

  options = {
    min: 8,
    max: 100,
    ease: 'linear',
    speed: 200,
    trickleSpeed: 300,
    meteor: true,
    spinner: true,
    spinnerPosition: 'right',
    direction: 'ltr+',
    color: 'red',
    thick: true
  };

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  onStarted() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  onCompleted() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

}
