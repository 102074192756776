import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { _SERVEROOT, AUTHORIZATION } from '../../routes/server-root';
import { AttrClasseModel } from '../models/attrClasseModel.service';

declare let $: any;
declare let Promise: any;

@Injectable()
export class attrClasseCtrl {
    constructor(public http: HttpClient) {
    }

    createClasse(data: AttrClasseModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                annee: AUTHORIZATION.ecoParam.idannee,
                login_user: data.login_user,
                pp: data.pp,
                //id_classe:any,
                matiere: data.matiere
            };
            this.http.post(_SERVEROOT.attribute_class, insert, AUTHORIZATION.token).subscribe(
                (data) => {
                    resolve(data)
                },
                (err) => {
                    reject(err)
                })
        })
    }
}