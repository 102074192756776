import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { _SERVEROOT, AUTHORIZATION } from '../../routes/server-root';
import { ProfsModel } from '../models/profsModel.service';
import {AttrClasseModel} from "../models/attrClasseModel.service";

declare let $: any;
declare let Promise: any;

@Injectable()
export class profsCtrl {
    constructor(public http: HttpClient) {
    }
    

    /*************************
     * 
     *  Toutes les methodes pour les profs
     * 
     *************************
     */

    createCompte(data: ProfsModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    route: data.route,
                    code_unique: data.code_unique,
                    fonction: data.fonction,
                    contact: data.contact,
                    role: data.role,
                    id_role: data.id_role,
                    redirect_route: data.redirect_route,
                    login: data.login
                }
                ;
            this.http.post(_SERVEROOT.addRegister, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    updateProfs(data: ProfsModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    nom: data.nom,
                    prenom: data.prenom,
                    fonction: data.fonction,
                    contact: data.contact,
                    sexe: data.sexe,
                    login_user: data.login_user
                }
                ;
            this.http.post(_SERVEROOT, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    updateProfsClasseMatere(data?: AttrClasseModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    // nom: data.nom,
                    // prenom: data.prenom,
                    // fonction: data.fonction,
                    // contact: data.contact,
                    // sexe: data.sexe,
                    login_user: data.login_user
                }
                ;
            this.http.post(_SERVEROOT.updateUser_by_class, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    getProfs(){
        return new Promise((resolve,reject)=>{

            this.http.get(_SERVEROOT.listProfessor+ "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole,AUTHORIZATION.token)
            .subscribe(
                (data)=>{
                    resolve(data)
                },
                (err)=>{ 
                    reject(err)
                }
            )
        })
    }
    getListeClaseProfs(){
        return new Promise((resolve,reject)=>{

            this.http.get(_SERVEROOT.listClass_by_user+ "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole+"&code_user="+ AUTHORIZATION.ecoParam.code_user+"&annee="+AUTHORIZATION.ecoParam.idannee,AUTHORIZATION.token)
            .subscribe(
                (data)=>{
                    resolve(data)
                },
                (err)=>{
                    reject(err)
                }
            )
        })
    }
    getListeMatiereProfs(id_classe:any){
        return new Promise((resolve,reject)=>{

            this.http.get(_SERVEROOT.listClass_by_user+ "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole+"&id_classe="+ id_classe+"&annee="+AUTHORIZATION.ecoParam.annee,AUTHORIZATION.token)
            .subscribe(
                (data)=>{
                    resolve(data)
                },
                (err)=>{
                    reject(err)
                }
            )
        })
    }
    getListeClassebyProfs(code_user?:any){
        return new Promise((resolve,reject)=>{

            this.http.get(_SERVEROOT.listClass_by_user+ "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole+"&code_user="+ code_user+"&annee="+AUTHORIZATION.ecoParam.annee,AUTHORIZATION.token)
            .subscribe(
                (data)=>{
                    resolve(data)
                },
                (err)=>{
                    reject(err)
                }
            )
        })
    }


    getListeClaseProfsMatieres(code_user?:any,id_classe?:any){
        return new Promise((resolve,reject)=>{

            this.http.get(_SERVEROOT.listUser_by_class+ "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole+"&annee="+AUTHORIZATION.ecoParam.idannee+"&code_user="+ code_user+"&id_classe="+id_classe,AUTHORIZATION.token)
                .subscribe(
                    (data)=>{
                        resolve(data)
                    },
                    (err)=>{
                        reject(err)
                    }
                )
        })
    }
    
}