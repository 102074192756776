import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eleves',
  templateUrl: './eleves.component.html',
  styleUrls: ['./eleves.component.css']
})
export class ElevesComponent implements OnInit {
public tab_active:any={
  current:'',
  last:''
}
  constructor() { }

  ngOnInit() {
  }
activeTabs(elemt,event){
  return 'active'
}
}
