import { Injectable, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AUTHORIZATION, _SERVEROOT } from "../routes/server-root";
import { HttpClient } from "@angular/common/http";
import * as cryptojs from 'crypto-js';
declare let $: any;
declare let Promise: any;
@Injectable()
export class HelpersService implements OnInit {

    config = {
        displayKey: "description",
        search: true,
        height: 'auto',
        placeholder: 'Selectionnez la nationalité',
        customComparator: () => {
        },
        moreText: 'un element',
        limitTo: 20,
        searchPlaceholder: 'Rechercher...'
    };
    public nationality: string[] = [
        'Algérienne', 'Angolaise', 'Béninoise', 'Burkinabè', 'Camerounaise', 'Canadienne', 'Congolaise', 'Egyptienne', 'Européene',
        'Française', 'Gabonaise', 'Guinéenne', 'Ivoirienne', 'Madagascar', 'Malienne', 'Marocainne', 'Mauritanien', 'Nigérienne', 'Sénégalaise',
        ' Sud-Africaine', 'Syrienne', 'Tchadienne', 'Tunisienne', 'Turc',
    ];

    public Nbretype_note: any;
    public role_auth: any = [];
    public dataEole: any = [];
    public ScolariteByYear: any = [];
    public dataCompAll: any = [];
    public dataListComp: any = [];
    public fonction_liste: any[] = ["", "Professeur", "Educateur", "Econnome", "Censeur", "Directeur", "Proviseur", "Informaticien", "Comptable"];
    public routing_liste: any[] = ["", "eleves", "programme", "etablissement", "dashboard", "utilisateurs", "professeurs", "depenses-paies", "consultation", "compte-rendu", "scolarite", "gestion-classes", "versement", "inscription", "paramecole", "historique", "livret-scolaire", "fiche", "classe", "bulletin", "moyenne", "heures-abscences", "notes", ""];
    public dataCoef: any[] = ["", 1, 2, 3, 4, 5];
    public logoGet: string = "usp";
    public codeEtabSelect: string = "";
    public LibFilierSelect: string = "";
    /**
     * **************************
     *  Form vailidation parttern
     ****************************
     */
    usDatePattern = "/^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/";
    ptDatePattern = "/^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g";
    namePattern = "^[a-zA-Z 0-9_-ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ]{3,100}$";
    namePatternSeconde = "^[\s-.,_ a-zA-Z0-9áàâäãéèêëíìîïóòôöõúùûüýÿÁÀÂÄÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÝ:/?]{3,50}$#";
    matricule = "^[a-zA-Z 0-9_-]{9,9}$";
    PhonePattern = "^[0-9]{8}$";
    numberPattern = "^[0-9]*";
    numberPatternzero = "^[1-9][0-9]*";
    emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}$";
    dataSite: any[] = [];
    data_etab_Site: any[] = [];


    constructor(public http: HttpClient, public router: Router) {
    }

    public results: any;
    public geneVars: string;

    ngOnInit() {
    }

    /**
     * permet de verifier si une chaine est dans un tableau
     * @param string
     * @param array
     * @returns {boolean}
     */
    in_array(string, array) {
        let result = false;
        for (let i = 0; i < array.length; i++) {
            if (array[i] == string) {
                result = true;
            }
        }
        return result;
    }

    /**
     * return l'url
     */
    uri() {
        return (this.router.url.replace("/", ""));
    }

    /**
     * Permet de calculer la difference de entre deux date
     * @param d1
     * @param d2
     * @returns {number}
     */
    dateDiff(d1, d2) {
        let WNbJours = d2.getTime() - d1.getTime();
        return Math.ceil(WNbJours / (1000 * 60 * 60 * 24));
    }

    /**
     * permet de formater la date au format aaaammjj
     * @param d
     * @param s
     * @returns {string}
     */
    dateFormatInverse(d: string, s?: string) {

        // Separation de la date a partie de /
        let format = d.split('/');

        // Recuperation des date d=jour,m=mois,y=annee
        let day = format[0];
        let month = format[1];
        let year = format[2];


        // Formatage de la date yyyymmjj
        let dateFormatInv;
        if (s == "" || s == undefined) {
            dateFormatInv = year + month + day;
        } else {
            dateFormatInv = year + s + month + s + day;
        }


        return dateFormatInv;

    }

    /**
     * permet de formater la date au format jj-mm-aaaa
     * @param d
     * @param s
     * @returns {string}
     */
    dateFormatformer() {
        let now = new Date();
        let annee: any = now.getFullYear();
        let mois: any = now.getMonth() + 1;
        let jour: any = now.getDate();

        if (mois < 10) {
            mois = '0' + mois;
        }
        if (jour < 10) {
            jour = '0' + jour;
        }

        let dateFormatI = jour + "-" + mois + "-" + annee;

        return dateFormatI;

    }

    /**
     * Permet de vider les variables
     * @param data
     * @param frm
     */
    clearData(data: any, frm: any) {
        data = frm;
    }

    dDiff(d_s, d_e) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.diffdate + "?date_in=" + d_s + "&date_out=" + d_e, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    inInterval(num) {
        if (num > 0 && num <= 9) {
            return 0 + '' + num;
        } else {
            return num;
        }
    }

    genereteVars(c): string {
        return this.geneVars + "" + c
    }

    getMonday(date) {
        return new Date(date.setDate(date.getDate() - date.getDay() + 1)).toLocaleDateString();
    }

    getSunday(date) {
        return new Date(date.setDate(date.getDate() - date.getDay() + 7)).toLocaleDateString();
    }

    /*
     Appreciation
     */
    Setappreciation(MoyEntree: any) {
        let observation: string = "";
        if (MoyEntree < 10) {
            observation = "Insuffisant";
        } else if (MoyEntree >= 10 && MoyEntree < 12) {
            observation = "Passable";
        }
        else if (MoyEntree >= 12 && MoyEntree < 14) {
            observation = "Assez Bien";
        }
        else if (MoyEntree >= 14 && MoyEntree < 15) {
            observation = "Bien";
        }
        else if (MoyEntree >= 15 && MoyEntree < 17) {
            observation = "Très Bien";
        }
        else if (MoyEntree >= 17 && MoyEntree < 20) {
            observation = "Excellent";
        }
        return observation;
    }

    /*
     permet de limiter le nombre de carateres
     */
    LimiteChar(Chadata: string) {
        let dataChaine = Chadata.split(" ");
        let chaineAsoo: string = "";
        if (dataChaine[1] == "" || dataChaine[1] == undefined) {
            return Chadata.substring(0, 4).toUpperCase();
        } else {
            for (let i = 0; i < dataChaine.length; i++) {
                if (chaineAsoo == "") {
                    chaineAsoo = dataChaine[i].substring(0, 1).toUpperCase();
                } else {
                    chaineAsoo = chaineAsoo + "-" + dataChaine[i].substring(0, 1).toUpperCase();
                }
            }
            return chaineAsoo;
        }
    }

    // getMonday(d) {
    //     d = new Date(d);
    //     let day = d.getDay(),
    //         diff = d.getDate() - day + (day == 0 ? -6:1)+1;
    //     let dateok=new Date(d.setDate(diff));
    //     let jour=dateok.getUTCDay();
    //     console.log("Lundi "+dateok.getUTCDate()+"/"+dateok.getUTCMonth()+"/"+dateok.getUTCFullYear());
    //     return "Lundi "+dateok.getUTCDate()+"/"+dateok.getUTCMonth()+"/"+dateok.getUTCFullYear();
    //
    // }
    // getFriday(d) {
    //     d = new Date(d);
    //     let day = d.getDay(),
    //         diff = d.getDate() - day + (day == 0 ? -2:7)+1;
    //     let dateok=new Date(d.setDate(diff));
    //     console.log("Dimanche "+dateok.getUTCDate()+"/"+dateok.getUTCMonth()+"/"+dateok.getUTCFullYear())
    //     return "Vendredi "+dateok.getUTCDate()+"/"+dateok.getUTCMonth()+"/"+dateok.getUTCFullYear();
    // }

    /**
     * permet d'uploder une image
     * @param caption
     * @param fileToUpload
     */
    postFile(fileToUpload: File) {
        const endPoint = _SERVEROOT.av;
        const formData: FormData = new FormData();
        formData.append('photo', fileToUpload, fileToUpload.name);
        //formData.append('ImageCaption',caption);
        return this.http.post(endPoint, formData, AUTHORIZATION.token)
    }

    Cleartime(timer) {
        clearInterval(timer);
    }


    viewUsers() {
        this.showRoles()
            .then((data) => {
                this.role_auth = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    viewCompo() {
        this.showComp()
            .then((data) => {
                this.dataCompAll = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    viewListeCompo() {
        this.showListeComp()
            .then((data) => {
                this.dataListComp = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    viewNbreTypeNote(lib_mat: any, id_classe: any, lib_compo: any, type_note: any) {
        this.CountNote(lib_mat, id_classe, lib_compo, type_note)
            .then((data) => {
                this.Nbretype_note = data.data;
                if (data.statut == false) {
                    this.Nbretype_note = 0;
                }
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    viewDistincttypeNote(lib_mat: any, id_classe: any, lib_compo: any) {
        this.typeNote(lib_mat, id_classe, lib_compo)
            .then((data) => {
                console.log(data.data);
                //this.Nbretype_note = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    viewScolariteByYear() {
        this.getScolariteByYear()
            .then((data) => {
                this.ScolariteByYear = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }


    /*
     *--------------------------                                    ------------
     * -------------------------  Liste des methodes en execution   ------------
     * -------------------------                                    ------------
     */

    /*
     * Liste des roles des etablissement
     */
    showRoles() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listRole, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }
    /*
      * id de la derniere année
      */
    showLastYear(code_ecole?: any) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.getLastYear + "?code_ecole=" + code_ecole, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }
    /*
         * liste Ecoles
         */
    getListeEcole() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listAllsEstablishment, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }
    getListeEtab_by_Site(idsites: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listeEtb_site + "?idsites=" + idsites, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }
    showListEcole() {
        this.getListeEcole()
            .then((data) => {
                this.dataEole = data.data;
            })
            .catch((error) => {
                console.log("error")
            })
    }
    showList_etab_by_Site(idsites?: string) {
        this.getListeEtab_by_Site(idsites)
            .then((data) => {
                this.data_etab_Site = data.data;

            })
            .catch((error) => {
                console.log("error")
            })
    }
    /*
     * liste des categories des composition
     */
    showComp() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.getCatCompo + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }

    /*
     * liste  des composition
     */
    showListeComp() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listCompo + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }

    /*
     obtenir le nombre de type de note: Note1,...(2)
     */
    CountNote(lib_mat: any, id_classe: any, lib_compo: any, type_note: any) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.nombreTypeNote + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&annee=" + AUTHORIZATION.ecoParam.idannee + "&lib_mat=" + lib_mat + "&id_classe=" + id_classe + "&code_user=" + AUTHORIZATION.ecoParam.code_user + "&lib_compo=" + lib_compo + "&type_note=" + type_note, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }

    /*
     Obtenir les differents type de note: Devoir1,Note1,...
     */
    typeNote(lib_mat: any, id_classe: any, lib_compo: any) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.typeNote + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&annee=" + AUTHORIZATION.ecoParam.annee + "&lib_mat=" + lib_mat + "&id_classe=" + id_classe + "&code_user=" + AUTHORIZATION.ecoParam.code_user + "&lib_compo=" + lib_compo, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }

    /*
     Liste des scolarité
     */
    getScolariteByYear() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.getScolarite + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&annee=" + AUTHORIZATION.ecoParam.idannee, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }
    /*
     Liste des classe
     */
    getClasseScole(id_niveau?: string) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.searchClasseByNiveau + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&id_niveau=" + id_niveau, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })

    }


    printRecu(paramMatricule) {
        window.open(_SERVEROOT.recuins + "?id_ins=" + paramMatricule, '_blank');
    }
    FichePre(paramMatricule) {
        window.open(_SERVEROOT.fichePrescription + "?matricule=" + paramMatricule, '_blank');
    }
    printBulletin(matricule, id_classe, id_compo) {
        window.open(_SERVEROOT.bulletinTsem1 + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&id_annee=" + AUTHORIZATION.ecoParam.idannee + "&matricule=" + matricule + "&id_classe=" + id_classe + "&id_compo=" + id_compo, '_blank');
    }

    printRecuVersement(paramMatricule) {
        window.open(_SERVEROOT.recuvers + "?id_vers=" + paramMatricule, '_blank');
    }

    /*
     Activation de compte utilisateurs
     */

    activateUsers(id_register: any) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                id_register: id_register,
            }
                ;
            this.http.post(_SERVEROOT.activeCompte, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    /*
     desActivation de compte utilisateurs
     */

    desactivateUsers(id_register: any) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                id_register: id_register,
            }
                ;
            this.http.post(_SERVEROOT.desableCompte, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    firstChaine(e) {
        let dataC = e.split(' ');
        let lgString:string="";
        for (let i = 0; i < dataC.length; i++) {
            if(lgString===""){
                lgString = dataC[i].substr(0,1);
            }else{
                lgString = lgString + dataC[i].substr(0,1);
            }
        }
        return lgString;
    }
}
export function cryptSession(session_string: string, key_crypt: any) {
    return encryptWithAES(session_string, key_crypt)
  }
  function encryptWithAES(text: string, passphrase: any) {
    return cryptojs.AES.encrypt(text, passphrase).toString();
  }
  export function decode64(text: string) {

    text = text.replace(/\s/g, "");
  
    if (!(/^[a-zàâäçdeéèêëiîïôöuùûü0-9\+\/\s]+\={0,2}$/i.test(text)) || text.length % 4 > 0) {
      throw new Error("Not a base64-encoded string.");
    }
    let digits = "AÂÄBCDEÊËFGHIÎÏJKLMNOÔÖPQRSTUÛÜVWXYZàâäabcçdeéèêëfghiîïjklmnoôöpqrstuùûüvwxyz0123456789+/",
      cur, prev = 0, digitNum, i = 0,
      result = [];
  
    text = text.replace(/=/g, "");
  
    while (i < text.length) {
  
      cur = digits.indexOf(text.charAt(i));
      digitNum = i % 4;
  
      switch (digitNum) {
  
        //case 0: first digit - do nothing, not enough info to work with
        case 1:
          //second digit
          result.push(String.fromCharCode(prev << 2 | cur >> 4));
          break;
  
        case 2:
          //third digit
          result.push(String.fromCharCode((prev & 0x0f) << 4 | cur >> 2));
          break;
  
        case 3:
          //fourth digit
          result.push(String.fromCharCode((prev & 3) << 6 | cur));
          break;
      }
  
      prev = cur;
      i++;
    }
  
    return result.join("");
  }