import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GestionService } from 'src/app/service/function/gestion.service';
import { HelpersService } from 'src/app/helper/helper.service';

declare let $: any;
@Component({
    selector: 'app-droit',
    templateUrl: './droit.component.html',
    styleUrls: ['./droit.component.css']
})
export class DroitComponent implements OnInit {
    faculte: string;
    id: string;
    dataConsultFiliere: any = [];
     customers: any[] = [{
        "ID": 1,
        "CompanyName": "Super Mart of the West",
        "Address": "702 SW 8th Street",
        "City": "Bentonville",
        "State": "Arkansas",
        "Zipcode": 72716,
        "Phone": "(800) 555-2797",
        "Fax": "(800) 555-2171",
        "Website": "http://www.nowebsitesupermart.com"
    }, {
        "ID": 2,
        "CompanyName": "Electronics Depot",
        "Address": "2455 Paces Ferry Road NW",
        "City": "Atlanta",
        "State": "Georgia",
        "Zipcode": 30339,
        "Phone": "(800) 595-3232",
        "Fax": "(800) 595-3231",
        "Website": "http://www.nowebsitedepot.com"
    }, {
        "ID": 3,
        "CompanyName": "K&S Music",
        "Address": "1000 Nicllet Mall",
        "City": "Minneapolis",
        "State": "Minnesota",
        "Zipcode": 55403,
        "Phone": "(612) 304-6073",
        "Fax": "(612) 304-6074",
        "Website": "http://www.nowebsitemusic.com"
    }];
    constructor(private route: ActivatedRoute, public gestion: GestionService, public helpe: HelpersService) { }

    table_annee_academique_master: any = [
        {
            libelle: 'Kit',
            l1: 50000,
            l2: 50000,
        },
        {
            libelle: 'Frais d\'inscription',
            l1: 225000,
            l2: 225000,
        },
        {
            libelle: '1er versement',
            l1: 212500,
            l2: 212500,
        }
        ,
        {
            libelle: '2ème versement',
            l1: 212500,
            l2: 212500,
        }, {
            libelle: 'Scolarité',
            l1: 425000,
            l2: 425000,
        },
        {
            libelle: 'Frais annexe',
            l1: 0,
            l2: 0,
        },
        {
            libelle: 'CS PP 0%',
            l1: -0,
            l2: -0,
        },
        {
            libelle: 'Total à payer',
            l1: 700000,
            l2: 700000,
        }
    ]

    table_annee_academique: any = [
        {
            libelle: 'Kit',
            l1: 50000,
            l2: 50000,
            l3: 50000
        },
        {
            libelle: 'Frais d\'inscription',
            l1: 225000,
            l2: 225000,
            l3: 225000
        },
        {
            libelle: '1er versement',
            l1: 125000,
            l2: 125000,
            l3: 187500
        }
        ,
        {
            libelle: '2ème versement',
            l1: 125000,
            l2: 125000,
            l3: 187500
        }, {
            libelle: 'Scolarité',
            l1: 250000,
            l2: 250000,
            l3: 370000
        },
        {
            libelle: 'Frais annexe',
            l1: 0,
            l2: 0,
            l3: 0
        },
        {
            libelle: 'CS PP 0%',
            l1: -0,
            l2: -0,
            l3: -0
        },
        {
            libelle: 'Total à payer',
            l1: 525000,
            l2: 525000,
            l3: 650000
        }
    ]

    ngOnInit() {

        $("html, body").animate({ scrollTop: 0 }, "slow");

        this.dataConsultFiliere = JSON.parse(localStorage.getItem("param_cons"));

        setTimeout(() => {
            this.gestion.searchClasse_param(this.dataConsultFiliere.code_ecole, this.dataConsultFiliere.lib_classe, "");
        }, 2000);

        // this.id = this.route.snapshot.paramMap.get("id");

    }
    viewModalite(e: any) {
         this.gestion.showScolarite(e.code_ecole, "3", e.id_niveau);
         this.gestion.showModalite(e.code_ecole, "3", e.id_niveau,"");
        console.log(e)
    }
}
