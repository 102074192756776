import {Injectable} from '@angular/core';
import {ecoleCtrl} from '../../database/controllers/ecoleCtrl.service';

@Injectable()
export class EcoleService {
    dataSchool: any = [];

    constructor(public ecoleCtrl: ecoleCtrl) {
    }

    showEcole() {
        this.ecoleCtrl.getDataEtbcs()
            .then((data) => {
                this.dataSchool = data.data;
            })
            .catch((err) => {
            })
    }

    showAllEcole(paramEtab?: any) {
        this.ecoleCtrl.getDataAllEtbcs(paramEtab)
            .then((data) => {
            console.log(data.data);
                this.dataSchool = data.data;
            })
            .catch((err) => {
            })
    }
}
