import { Injectable } from '@angular/core';

@Injectable()
export class EcoleModel {

  constructor() { }
  public raisonsociale:string;
  public email:string;
  public contact:string;
  public adresse:string;
  public fondateur:string;
  public directeur:string;
  public site_geo:string;
  public logo:string;
  public credo:string;
  public statut:any;


}