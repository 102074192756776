import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {HttpInterceptorService} from "./service/httpintercept.service";
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers :[httpInterceptorProviders]
})
export class HttpwModule { }