import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {_SERVEROOT, AUTHORIZATION} from '../../routes/server-root';
import {HelpersService} from "../../helper/helper.service";

declare let $: any;
declare let Promise: any;

@Injectable()
export class MoyCtrl {
    constructor(public http: HttpClient,private helpe:HelpersService) {
    }

    addMoys(moyDta: any) {
        this.createMoyenne(moyDta)
            .then((data) => {
                //this.dataListeNoteviewByProf = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })
    }

    createMoyenne(data: any) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    code_user: AUTHORIZATION.ecoParam.code_user,
                    annee: AUTHORIZATION.ecoParam.idannee,
                    matricule: data.matricule,
                    lib_mat: data.lib_mat,
                    type_mat: data.type_mat,
                    moys: data.moys,
                    rang: data.rang,
                    lib_compo: data.lib_compo,
                    id_classe: data.id_classe,
                    coef: data.coef,
                    appreciation: data.appreciation,
                }
                ;
            this.http.post(_SERVEROOT.addMoyenne_mat, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    createMoyenneCompo(data: any) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    code_user: AUTHORIZATION.ecoParam.code_user,
                    annee: AUTHORIZATION.ecoParam.idannee,
                    matricule: data.matricule,
                    rang: data.rang,
                    moyenne: data.moyenne,
                    moyclasse: data.moyclasse,
                    moy_coef: data.moy_coef,
                    id_compo: data.id_compo,
                    id_classe: data.id_classe,
                    t_coef: data.t_coef,
                    effectif: data.effectif,
                    moy_max: data.moy_max,
                    moy_min: data.moy_min,
                    appreciation: data.appreciation,
                }
                ;

            this.http.post(_SERVEROOT.addMoyenne_compo, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getMoyCompo(id_classe:any,lib_compo:any){
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.ListMoyenne_mat + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole+"&annee="+AUTHORIZATION.ecoParam.idannee+"&id_classe="+id_classe+"&lib_compo="+lib_compo, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    getMoyCompoByclasse(id_classe:any,lib_compo:any){
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.ListMoyenne_compo + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole+"&annee="+AUTHORIZATION.ecoParam.idannee+"&id_classe="+id_classe+"&lib_compo="+lib_compo, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    getStudentClasse(id_classe:any){
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.list_payment + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole+"&annee="+AUTHORIZATION.ecoParam.idannee+"&id_classe="+id_classe, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }


}