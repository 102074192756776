import {Injectable} from '@angular/core';

@Injectable()
export class ProfsModel {

    constructor() {
    }

    public nom: string;
    public prenom: string;
    public fonction: string;
    public contact: string;
    public route: any;
    public sexe: string;
    public login: string;
    public role: string;
    public id_role: string;
    public code_unique: string;
    public redirect_route: string;
    public login_user: string;
    public code_ecole: string;

}