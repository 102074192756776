import { Injectable } from '@angular/core';

@Injectable()
export class programModel {

    constructor() { }
    public lib_program:string;
    public id_mat:string;
    public id_classe:string;
    public date_p:any;
    public heure_debut:any;
    public heure_fin:any;
    public id:any;
}