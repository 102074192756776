import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-error',
    templateUrl: './error404.component.html',
    styleUrls: ['./error404.component.css']
})
export class ErrorComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
