import {Injectable} from '@angular/core';
import {CaisseCtrl} from '../../database/controllers/caisseCtrl.service';

@Injectable()
export class CaisseService {
    dataScolarite: any = [];
    dataInscritToday: any = [];
    TotalTodayInscription: any ;
    TotalTodayVersemnt: number = 0;
    dataVersToday: any = [];
    dataInscrit: any = [];
    dataNonSolde: any [] = [];
    dataSolde: any [] = [];
    TotalInscription: number = 0;
    dataVers: any = [];

    constructor(public caisseCtrl: CaisseCtrl) {
    }

    /**
     * permet de rechercher la scolarité d'un niveau
     * @param stringSearch
     */
    searScolarite(stringSearch) {
        this.caisseCtrl.getScolarite(stringSearch)
            .then((data) => {
                this.dataScolarite = data[0].scolarite;

            })
            .catch((err) => {

            })

    }

    /**
     * Obtenir les inscrits du jour
     */
    getTodayInscription(classe?: string) {
        this.caisseCtrl.getInscritToday(classe)
            .then((data) => {
                this.dataInscritToday = data.data;
                this.TotalTodayInscription=0;
                for (let i = 0; i < data.data.length; i++) {
                    this.TotalTodayInscription = this.TotalTodayInscription + parseInt(data.data[i].montant_ins)
                }
            })
            .catch((err) => {

            })

    }

    /**
     * liste des inscrit de l'année
     * @param classe
     */
    listInscrit(classe?: string) {
        this.caisseCtrl.list_inscrit(classe)
            .then((data) => {

                this.dataInscrit = data.data;
                this.dataNonSolde = [];
               this.dataSolde = [];
              //  console.log( this.dataNonSolde)
                for (let i = 0; i < data.data.length; i++) {
                    if(parseInt(data.data[i].reliquat)==0){

                       this.dataSolde.push(data.data[i]);
                    }else {
                        this.dataNonSolde.push(data.data[i]);
                    }
                 //   this.TotalInscription = this.TotalTodayInscription + parseInt(data.data[i].montant_ins)
                }
            })
            .catch((err) => {

            })

    }

    /**
     * Obtenir les versement du jour
     */
    getPaymentDay(lib_classe?: string) {
        this.caisseCtrl.payment_day(lib_classe)
            .then((data) => {
                this.dataVersToday = data.data;

                for (let i = 0; i < data.data.length; i++) {
                    this.TotalTodayVersemnt = this.TotalTodayVersemnt + parseInt(data.data[i].montant_vers)
                }
            })
            .catch((err) => {

            })

    }

    /**
     * Obtenir tous les versements
     */
    getAllPayment(lib_classe?: string) {
        this.caisseCtrl.list_payment(lib_classe)
            .then((data) => {
                this.dataVers = data.data;

                // for(let i=0;i<data.data.length;i++){
                //   this.TotalTodayVersemnt=  this.TotalTodayVersemnt + parseInt(data.data[i].montant_vers)
                // }
            })
            .catch((err) => {

            })

    }
    /**
     * Imprimer un recu
     */
    getRecu(matricule: string) {
        this.caisseCtrl.PrintRecuInscription(matricule)
            .then((data) => {
            console.log(data);
               // this.dataVers = data.data;

                // for(let i=0;i<data.data.length;i++){
                //   this.TotalTodayVersemnt=  this.TotalTodayVersemnt + parseInt(data.data[i].montant_vers)
                // }
            })
            .catch((err) => {

            })

    }

}
