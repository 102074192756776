import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { _SERVEROOT, AUTHORIZATION } from '../../routes/server-root';
import { EleveModel } from '../models/eleveModel.service';

declare let $: any;
declare let Promise: any;

@Injectable()
export class EleveCtrl {
    constructor(public http: HttpClient) {
    }
    

    /*************************
     * 
     *  Toutes les methodes pour l'ajout
     * 
     *************************
     */

    createEleves(data: EleveModel) {
      
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    matricule: data.matricule,
                    statut: data.statut,
                    nom: data.nom,
                    prenom: data.prenom,
                    datenaiss: data.datenaiss,
                    lieunaiss: data.lieunaiss,
                    sexe: data.sexe,
                    contact: data.contact,
                    nationalite: data.nationalite,
                    code_parent: data.code_parent,
                    nom_parent: data.nom_parent,
                    prenom_parent: data.prenom_parent,
                    fonction: data.fonction,
                    typeparent: data.typeparent,
                    domicile: data.domicile,
                    email_p: data.email_p,
                }
                ;
            this.http.post(_SERVEROOT.addStudent, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

     updateEleves(data: EleveModel) {

        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    matricule: data.matricule,
                    statut: data.statut,
                    nom: data.nom,
                    prenom: data.prenom,
                    datenaiss: data.datenaiss,
                    lieunaiss: data.lieunaiss,
                    sexe: data.sexe,
                    contact: data.contact,
                    nationalite: data.nationalite,
                    code_parent: data.code_parent,
                    nom_parent: data.nom_parent,
                    prenom_parent: data.prenom_parent,
                    fonction: data.fonction,
                    typeparent: data.typeparent,
                    domicile: data.domicile,
                }
                ;
            this.http.post(_SERVEROOT.updateStudent, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    Upload(data) {
      
        return new Promise((resolve, reject) => {
           
            this.http.post(_SERVEROOT.av, data, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }
    getEleves(){
        return new Promise((resolve,reject)=>{
            this.http.get(_SERVEROOT.listStudents+"?code_ecole="+AUTHORIZATION.ecoParam.code_ecole,AUTHORIZATION.token)
            .subscribe(
                (data)=>{
                    resolve(data)
                },
                (err)=>{ 
                    reject(err)
                }
            )
        })
    }
    getElevesNonInscrit(){
        return new Promise((resolve,reject)=>{
           // listNon_inscrit
            this.http.get(_SERVEROOT.listStudents+"?code_ecole="+AUTHORIZATION.ecoParam.code_ecole+"&annee="+AUTHORIZATION.ecoParam.idannee,AUTHORIZATION.token)
            .subscribe(
                (data)=>{
                    resolve(data)
                },
                (err)=>{
                    reject(err)
                }
            )
        })
    }


}