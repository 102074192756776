import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintService } from './function/print.service';
import { PersonnelsService } from './function/personnels.service';
import { MoyennesService } from './function/moyennes.service';
import { GestionService } from './function/gestion.service';
import { ElevesService } from './function/eleves.service';
import { DashboardService } from './function/dashboard.service';
import {ToolsService} from './function/tools.service';
import {CaisseService} from './function/caisse.service';
import { EcoleService } from "./function/ecole.service";
import { ProfService } from './function/prof.service';
import { usersService } from './function/users.service';
import {ProgramService} from "./function/program.service";
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
      ToolsService,
      CaisseService,
      DashboardService,
      EcoleService,
      ElevesService,
      GestionService,
      MoyennesService,
      PersonnelsService,
      PrintService,
      usersService,
      ProfService,
      ProgramService
  ]
})
export class ServiceModule { }
