import {ToolsModule} from './tools/tools.module';
import {ElevesComponent} from './pages/eleves/eleves.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {ServiceModule} from './service/service.module';
import {DatabaseModule} from './database/database.module';
import {ComponentsModule} from './components/components.module';
import {AppRoutes} from './path/app.routing';
import {SettingService} from './setting/setting.service';
import {AuthService} from './auth/auth.service';
import {CookiesService} from './cookies/cookies.service';
import {CookieService} from 'ngx-cookie-service';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ToastrModule} from 'ngx-toastr';
import {NgProgressModule} from '@ngx-progressbar/core';
import {NgProgressRouterModule} from '@ngx-progressbar/router';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {HashLocationStrategy, LocationStrategy, APP_BASE_HREF,registerLocaleData} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {RouterResolver} from './router.resolver';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {TopnavComponent} from './shared/topnav/topnav.component';
import {SidebarComponent} from './shared/sidebar/sidebar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HelpersService} from './helper/helper.service';
import {jqxBarGaugeComponent} from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxbargauge';
import {jqxDataTableComponent} from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatatable';
import {jqxGridComponent} from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import {SelectDropDownModule} from 'ngx-select-dropdown';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {  NZ_I18N, en_US } from 'ng-zorro-antd';//NgZorroAntdModule
import en from '@angular/common/locales/en';
import {HttpwModule} from "./httpw/httpw.module";
import {ErrorComponent} from "./pages/error404/error404.component";
import { AccueilComponent } from './pages/accueil/accueil.component';
import { ContactComponent } from './pages/contact/contact.component';
import { RechercheComponent } from './pages/recherche/recherche.component';
import { FacultesComponent } from './pages/facultes/facultes.component';
import { EtudiantsComponent } from './pages/etudiants/etudiants.component';
import { EtudesComponent } from './pages/etudes/etudes.component';
import { PreinscriptionComponent } from './pages/preinscription/preinscription.component';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { ConnexionComponent } from './pages/connexion/connexion.component';
import {OwlModule} from "ngx-owl-carousel";
import {NgImageSliderModule} from "ng-image-slider";
import { GesspComponent } from './pages/gessp/gessp.component';
import { CpaComponent } from './pages/cpa/cpa.component';
import { UspComponent } from './pages/usp/usp.component';
import { NouvellesComponent } from './pages/nouvelles/nouvelles.component';
import { DonnerComponent } from './pages/donner/donner.component';
import { ConsultationComponent } from './pages/consultation/consultation.component';
import { IfomComponent } from './pages/ifom/ifom.component';
import { DroitComponent } from './pages/facultes/droit/droit.component';
import { LettreComponent } from './pages/facultes/lettre/lettre.component';
import { EnvironnementComponent } from './pages/facultes/environnement/environnement.component';
import { SciencesComponent } from './pages/facultes/sciences/sciences.component';
import { DxNumberBoxModule, DxCheckBoxModule, DxSelectBoxModule,DxDataGridModule } from 'devextreme-angular';
import { LSelect2Module } from 'ngx-select2';

registerLocaleData(en);
// import { NgZorroAntdModule } from 'ng-zorro-antd';

/** config angular i18n **/
//import en from '@angular/common/locales/en';
//import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
//registerLocaleData(en);
const ngWizardConfig: NgWizardConfig = {
    theme: THEME.default
};

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        TopnavComponent,
        SidebarComponent,
        DashboardComponent,
        ElevesComponent,
        jqxBarGaugeComponent,
        jqxDataTableComponent,
        jqxGridComponent,
        ErrorComponent,
        AccueilComponent,
        ContactComponent,
        RechercheComponent,
        FacultesComponent,
        EtudiantsComponent,
        EtudesComponent,
        PreinscriptionComponent,
        ConnexionComponent,
        GesspComponent,
        CpaComponent,
        UspComponent,
        NouvellesComponent,
        DonnerComponent,
        ConsultationComponent,
        IfomComponent,
        DroitComponent,
        LettreComponent,
        EnvironnementComponent,
        SciencesComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(AppRoutes, {useHash: false}),
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgProgressModule.forRoot(),
        NgProgressRouterModule.forRoot(),
        ComponentsModule,
        DatabaseModule,
        ServiceModule,
        ToolsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        SelectDropDownModule,
        NgxPaginationModule,
        NgxChartsModule,
        // NgZorroAntdModule,
        HttpwModule,
        NgWizardModule.forRoot(ngWizardConfig),
        OwlModule,
        NgImageSliderModule,
        DxSelectBoxModule,
        DxNumberBoxModule,
        DxCheckBoxModule,
        LSelect2Module,
        DxDataGridModule,
        
    ],
    providers: [
        RouterResolver,
        CookieService,
        AuthService,
        CookiesService,
        SettingService,
        HelpersService,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: APP_BASE_HREF,
            useValue: '/',
            
        },
        { provide: NZ_I18N, useValue: en_US }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
