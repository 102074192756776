import { Injectable } from '@angular/core';

@Injectable()
export class PreinscriptionModel {

    constructor() { }
    public matricule:string;
    public nom:string;
    public prenom:string;
    public sexe:string;
    public contact:string;
    public email:string;
    public code_parent:string;
    public contact_p:string;
    public email_p:string;
    public code_ecole:string;
    public nom_parent:string;
    public prenom_parent:string;
    public fonction:string;
    public id_cycle:string;
    public id_niveau:string;
    public title:string;
    public id_classe:string;
    public id_site:string;
}
