import {ClasseModel} from './models/classeModel.service';
import {ecoleCtrl} from './controllers/ecoleCtrl.service';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CycleModel} from './models/cycleModel.service';
import {GestionCtrl} from './controllers/gestionCtrl.service';
import {CategorieModel} from './models/categorieModel.service';
import {NiveauModel} from './models/niveauModel.service';
import {TypematiereModel} from './models/typematiereModel.service';
import {MatiereModel} from './models/matiereModel.service';
import {CompoModel} from './models/compoModel.service';
import {EleveModel} from './models/eleveModel.service';
import {EleveCtrl} from './controllers/eleveCtrl.service';
import {ScolariteModel} from './models/scolariteModel.service';
import {ScolariteCtrl} from './controllers/scolariteCtrl.service';
import {addInscriptionModel} from './models/addInscriptionModel.service';
import {CaisseCtrl} from './controllers/caisseCtrl.service';
import {addVersementModel} from './models/addVerementmodel.service';
import {ProfsModel} from './models/profsModel.service';
import {UsersModel} from './models/usersModel.service';
import {profsCtrl} from './controllers/profsCtrl.service';
import {usersCtrl} from './controllers/usersCtrl.service';
import {EcoleModel} from './models/ecoleModel.service';
import {NotesCtrl} from './controllers/notesCtrl.service';
import {NotesModel} from './models/notesModel.service';
import {attrClasseCtrl} from './controllers/attrClasseCtrl.service';
import {AttrClasseModel} from './models/attrClasseModel.service';
import {MoyCtrl} from "./controllers/moyCtrl.service";
import {programCtrl} from "./controllers/programCtrl.service";
import {programModel} from "./models/programModel.service";
import {PreinscriptionModel} from "./models/preInscription.service";
import {preinscriptionCtrl} from "./controllers/preInscriptionCtrl.service";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [ecoleCtrl, CycleModel, GestionCtrl, CategorieModel,
        NiveauModel, ClasseModel, TypematiereModel, MatiereModel,
        CompoModel, EleveModel, EleveCtrl, ScolariteModel, ScolariteCtrl,
        addInscriptionModel, CaisseCtrl, addVersementModel, ProfsModel, profsCtrl,
        UsersModel, usersCtrl, EcoleModel, NotesCtrl, NotesModel, attrClasseCtrl, AttrClasseModel, MoyCtrl, programCtrl, programModel,
        PreinscriptionModel,preinscriptionCtrl
    ]
})
export class DatabaseModule {
}
