import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
    selector: 'app-etudiants',
    templateUrl: './etudiants.component.html',
    styleUrls: ['./etudiants.component.css']
})
export class EtudiantsComponent implements OnInit {
   
    ImagesCalendrier = [
        {
            image: 'assets/img/usp/1.jpg',
            TitleImage: 'Formation 2020-2021',
            Titleevent: 'Rentrée scolaire',
            textImage: ' un enseignement de niveau universitaire de qualité tissé avec des normes académiques.'
        },
        {
            image: 'assets/img/usp/2.jpg',
            TitleImage: 'Rentrée scolaire 2020-2021',
            Titleevent: 'En salle de formation',
            textImage: 'un enseignement de niveau universitaire de qualité tissé avec des normes académiques.'
        },
        {
            image: 'assets/img/usp/3.jpg',
            TitleImage: "La cours de l'école",
            Titleevent: "La cours de l'école",
            textImage: 'un enseignement de niveau universitaire de qualité tissé avec des normes académiques.'
        }
    ];
    constructor() { }

    ngOnInit() {
        $('.parallax-window').parallax({ imageSrc: '../../../assets/img/carousel/2.jpg' });
    }

}
