import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class ToolsService {

  constructor(public router : Router) { }
  
  routerLink(value:string){
      this.router.navigate([value]);
  }

}
