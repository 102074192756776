import { Routes } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { ElevesComponent } from './../pages/eleves/eleves.component';
import { RouterResolver } from "../router.resolver";
import { ErrorComponent } from "../pages/error404/error404.component";
import { AccueilComponent } from "../pages/accueil/accueil.component";
import { ContactComponent } from "../pages/contact/contact.component";
import { RechercheComponent } from "../pages/recherche/recherche.component";
import { FacultesComponent } from "../pages/facultes/facultes.component";
import { EtudiantsComponent } from "../pages/etudiants/etudiants.component";
import { EtudesComponent } from "../pages/etudes/etudes.component";
import { PreinscriptionComponent } from "../pages/preinscription/preinscription.component";
import { ConnexionComponent } from "../pages/connexion/connexion.component";
import { GesspComponent } from '../pages/gessp/gessp.component';
import { CpaComponent } from '../pages/cpa/cpa.component';
import { UspComponent } from '../pages/usp/usp.component';
import { NouvellesComponent } from "../pages/nouvelles/nouvelles.component";
import { DonnerComponent } from "../pages/donner/donner.component";
import { ConsultationComponent } from "../pages/consultation/consultation.component";
import { IfomComponent } from "../pages/ifom/ifom.component";
import { DroitComponent } from "../pages/facultes/droit/droit.component";
import { SciencesComponent } from "../pages/facultes/sciences/sciences.component";
import { LettreComponent } from "../pages/facultes/lettre/lettre.component";
import { EnvironnementComponent } from "../pages/facultes/environnement/environnement.component";


export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'accueil',
        pathMatch: 'full',
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'consultation/:id',
        component: ConsultationComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'ifom',
        component: IfomComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'accueil',
        component: AccueilComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'presentation',
        component: ContactComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'nouvelles',
        component: NouvellesComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'donation',
        component: DonnerComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'groupe-enseig-superieur-agbe',
        component: GesspComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'groupe-scolaire-Agbe',
        component: CpaComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'u-saint-paul',
        component: UspComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'rechercher',
        component: RechercheComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'facultes',
        component: FacultesComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'consultation',
        component: DroitComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'facultes/scienes-economiques',
        component: SciencesComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'facultes/lettre-moderne',
        component: LettreComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'facultes/eau-environnement',
        component: EnvironnementComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'etudiants-actuel',
        component: EtudiantsComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'etudes',
        component: EtudesComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'pre-inscription',
        component: PreinscriptionComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    }, {
        path: 'mon-portail',
        component: ConnexionComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    },
    {
        path: 'eleves',
        component: ElevesComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver }
    }
    , {
        path: '**',
        component: ErrorComponent,
        canActivate: [AuthService],
        resolve: { crisis: RouterResolver },
    }

];
