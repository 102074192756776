import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ShareData} from "../routes/server-root";
/* import {NgbModule} from "@ng-bootstrap/ng-bootstrap"; */
import {SelectDropDownModule} from 'ngx-select-dropdown'
import {NgxPaginationModule} from "ngx-pagination";
import {addElevesComponent} from './student/add/addeleves.component';
import {ListstudentComponent} from './student/liststudent/liststudent.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {UpdatestudentComponent} from './student/updatestudent/updatestudent.component';
import {
    FilterPipe, InscriptionPipe, dateFormatPipe, RecuPipe, GroupByPipe,
    PointReplacerPipe, SearchUserPipe
} from '../tools/filter.pipe';
import {parCoursComponent} from "./student/parcours/parcours.component";
import {NzTableModule, NgZorroAntdModule, NzCheckboxModule, NzInputNumberModule} from "ng-zorro-antd";
//import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        /* NgbModule.forRoot(),*/
        SelectDropDownModule,
        NgxPaginationModule,
        NgZorroAntdModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NzTableModule,
        NzCheckboxModule,
        NzInputNumberModule
    ],
    declarations: [
        ShareData,
        addElevesComponent,
        parCoursComponent,
        ListstudentComponent,
        UpdatestudentComponent,
        FilterPipe,
        InscriptionPipe,
        dateFormatPipe,
        RecuPipe,
        GroupByPipe,
        PointReplacerPipe,
        SearchUserPipe,
    ],
    exports: [
        ShareData,
        addElevesComponent,
        parCoursComponent,
        ListstudentComponent,
        FilterPipe,
        InscriptionPipe,
        dateFormatPipe,
        RecuPipe,
        GroupByPipe,
        PointReplacerPipe,
        SearchUserPipe

    ]
})
export class ComponentsModule {
}
