import {Injectable} from '@angular/core';
import {profsCtrl} from '../../database/controllers/profsCtrl.service';


@Injectable()
export class ProfService {
    dataProf: any = [];
    dataProfUnique: any = [];
    dataClasseProf: any = [];
    dataClassebyProf: any = [];
    initdataProf: any = [];
    dataClassebyProfMatiere: any = [];
    viewUp: string = 'list';

    constructor(public prof: profsCtrl) {
    }


    /**************************************************
     *                                                *
     *    Methode concernant l'affichage des profs    *
     * ************************************************
     * ************************************************
     */
    showProfs() {
        this.prof.getProfs()
            .then((data) => {
                this.dataProf = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    showProfsListeClasse() {
        this.prof.getListeClaseProfs()
            .then((data) => {
                this.dataClasseProf = data.data;

                for (let i = 0; i < data.data.length; i++) {

                    if(this.check_data_in_array(this.dataProfUnique,data.data[i].id_classe)==0){
                        this.dataProfUnique.push({"id_classe":data.data[i].id_classe, "lib_classe":data.data[i].lib_classe});
                    }
                }

            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    // (idClasse) {
    //     this.prof.getListeMatiereProfs(idClasse)
    //         .then((data) => {
    //             this.dataClasseProf = data.data;
    //         })
    //         .catch((err) => {
    //             console.log("Un probleme est survenu, merci de réessayer.");
    //         })
    //
    // }
    showProfsMatiere(code_user?: any, id_classe?: any) {
        this.prof.getListeClaseProfsMatieres(code_user, id_classe)
            .then((data) => {

                this.dataClassebyProfMatiere = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

    showProfsClasse(code_user?: any) {
        this.prof.getListeClassebyProfs(code_user)
            .then((data) => {
                // this.dataClassebyProfMatiere = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }


    check_data_in_array(dataArray:any=[{}],valDat){
        let kval =0;
        for(let i=0;i<dataArray.length;i++){
            if(dataArray[i].id_classe==valDat){
                kval=1
            }
        }
        return kval;
    }
}
