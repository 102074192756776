import { Component, OnInit } from '@angular/core';
import {GestionService} from "../../service/function/gestion.service";
import {MoyennesService} from "../../service/function/moyennes.service";
import {CaisseService} from "../../service/function/caisse.service";
declare let $: any;
declare let Promise: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
 p:any;
 view: any[] = [700,500];

 // options
 showXAxis = true;
 showYAxis = true;
 gradient = false;
 showLegend = true;
 showXAxisLabel = true;
 xAxisLabel = 'Libellé';
 showYAxisLabel = true;
 yAxisLabel = 'Montant';
 timeline = true;
 autoScale = true;
 colorScheme = {
     domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
 };

 multi: any[] = [
     {
         "name": '12/10/2018',
         "series": [
             {
                 "name": "Inscription",
                 "value":5
             },

             {
                 "name": "Versement",
                 "value":180000
             },

             {
                 "name": "Soldé",
                 "value":14000
             },

             {
                 "name": "Non soldé",
                 "value":166000
             }
         ]
     },{
         "name": '14/10/2018',
         "series": [
             {
                 "name": "Inscription",
                 "value":90
             },

             {
                 "name": "Versement",
                 "value":1800000
             },

             {
                 "name": "Soldé",
                 "value":12899
             },

             {
                 "name": "Non soldé",
                 "value":230000
             }
         ]
     }
 ];

  constructor(public gestion: GestionService, public moyService: MoyennesService,public caisse: CaisseService) { }

  ngOnInit() {
      this.caisse.listInscrit("");
      this.moyService.showClasse("");
  }
}
