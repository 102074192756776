import {Injectable} from '@angular/core';
import {programCtrl} from "../../database/controllers/programCtrl.service";



@Injectable()
export class ProgramService {
  public  dataProgram:any=[];

    constructor(public progr: programCtrl) {
    }


    /**************************************************
     *                                                *
     *    Methode concernant l'affichage des programme    *
     * ************************************************
     * ************************************************
     */
    showProfs(userCode?:any) {
        this.progr.getProgramme(userCode)
            .then((data) => {
                this.dataProgram = data.data;
            })
            .catch((err) => {
                console.log("Un probleme est survenu, merci de réessayer.");
            })

    }

}
