import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {_SERVEROOT, AUTHORIZATION} from '../../routes/server-root';
import {PreinscriptionModel} from "../models/preInscription.service";

declare let $: any;
declare let Promise: any;

@Injectable()
export class preinscriptionCtrl {
    constructor(public http: HttpClient) {
    }


    /*************************
     *
     *  Toutes les methodes pour les profs
     *
     *************************
     */

    createPreInscrition(data: PreinscriptionModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    matricule: data.matricule,
                    nom: data.nom,
                    prenom: data.prenom,
                    sexe: data.sexe,
                    contact: data.contact,
                    email: data.email,
                    code_parent: data.code_parent,
                    email_p: data.email_p,
                    code_ecole: data.code_ecole,
                    nom_parent: data.nom_parent,
                    prenom_parent: data.prenom_parent,
                    fonction: data.fonction,
                    id_cycle: data.id_cycle,
                    id_niveau: data.id_niveau,
                    contact_p:data.contact_p,
                    id_classe:data.id_classe
                }
                ;
            this.http.post(_SERVEROOT.addPreStudent, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    // updateProfs(data: ProfsModel) {
    //     return new Promise((resolve, reject) => {
    //         let insert: any = {
    //                 matricule: AUTHORIZATION.ecoParam.code_ecole,
    //                 title: data.nom,
    //             }
    //             ;
    //         this.http.post(_SERVEROOT.addDossier, insert, AUTHORIZATION.token)
    //             .subscribe(
    //                 data => {
    //                     resolve(data);
    //                 },
    //                 err => {
    //                     reject(err);
    //                 }
    //             );
    //     });
    // }
}