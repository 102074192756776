import {CookiesService} from './../../cookies/cookies.service';
import {Component, OnInit} from '@angular/core';
import {AUTHORIZATION, _SERVEROOT} from "../../routes/server-root";
import {CookieService} from "ngx-cookie-service";
import {HttpClient} from "@angular/common/http";
import {HttpHeaders} from "@angular/common/http";
declare let $: any;
@Component({
    selector: 'tools-cmp',
    templateUrl: './tools.component.html',
    styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {
    tokenValidation: boolean = false;
    tokenEmailSafe: string;
    public password: any;
    cp: number;
    exp: number;
    timeoutId;
    dataInfo: any;

    constructor(public data: CookiesService, public cookieService: CookieService, public http: HttpClient) {
    }

    ngOnInit() {
        // this.exp = this.data.tokenExp;
        // this.TokenValidation();

        // let getCookies = this.cookieService.get('lasource');

        // if (getCookies) {
        //     this.dataInfo = JSON.parse(getCookies);
        //     // this.nomcomplet = jsp.nom_prenom;
        // }

    }

    TokenValidation() {

        this.tokenValidation = true;

        this.timeoutId = setInterval(() => {
            // $("#simpleModal").modal('show');
            // return
            let date = new Date();
            let Timestamp: string = "" + date.getTime();
            let sub = Timestamp.substring(0, 10);
            let cp = parseInt(sub);
            if (cp >= this.exp) {
                console.log('token expired');
                $("#simpleModal").modal('show');
                $("#simpleModal").css('display:block ;');
                this.tokenValidation = true;
                this.tokenEmailSafe = this.data.login;
                AUTHORIZATION.exp = true;
                this.tokenVFonction();
                this.cookieService.delete('lasource');
                this.cookieService.delete('lasourceConfig');
            }
        }, 1000);
    }

    tokenVFonction() {
        clearInterval(this.timeoutId);
    }

    ReAuth() {
        let me = this;
        $.ajax({
            url: "http://api-ecole.e2t.ci/admin/Auth/login",//_SERVEROOT.connection,
            type: 'POST',
            dataType: 'JSON',
            data: {
                "email": me.data.login,
                "pwd": $("#pwd").val()
            },
            success: function (data) {


                if (data.token) {

                    let httpOptions = {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + data.token
                        })
                    };

                    let expiredDate = new Date();

                    expiredDate.setDate(expiredDate.getDate() + 7);
                    me.cookieService.set('lasource', JSON.stringify(data), expiredDate, '/', 'localhost');
                    me.cookieService.set('lasourceConfig', JSON.stringify(data.config), expiredDate, '/', 'localhost');

                    // me.cookieService.set('lasourceTech', JSON.stringify(data));


                    me.exp = data.exp;
                    me.tokenValidation = false;
                    AUTHORIZATION.token = httpOptions;
                    me.TokenValidation();

                    $("#simpleModal").modal('hide');

                } else {
                    $("#incretourServer").show();
                }
            },
            error: function (error) {
                if (error.responseJSON.status = 2100) {
                    $("#incConnect").hide();
                    $("#incPwd").show();
                } else {
                    $("#incPwd").hide();
                    $("#incConnect").show();
                }
            }
        });
    }


}
