import {Component, OnInit, OnDestroy} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {HelpersService} from '../../../helper/helper.service';
import {EleveCtrl} from '../../../database/controllers/eleveCtrl.service';
import {EleveModel} from '../../../database/models/eleveModel.service';
import {ElevesService} from '../../../service/function/eleves.service';
import {_SERVEROOT, AUTHORIZATION} from 'src/app/routes/server-root';
import {HttpClient} from '@angular/common/http';
import 'lodash';
declare let _: any;
declare let $: any;
@Component({
    selector: 'addeleves-cmp',
    templateUrl: './addeleves.component.html',
    styleUrls: ['./addeleves.component.css']
})
export class addElevesComponent implements OnInit {
    nation: any;
    fileToUpload: File = null;
    selectedtFil: File = null;
    studentSubmitted = false;
    imageUrl: string = "/assets/img/Blank_Avatar.png";
    countPhone: number = 0;
    countmatricule: number = 0;
    countPhoneparent: number = 0;
    genre: any[] = ["Masculin", "Féminin"];

    constructor(public elevesService: ElevesService,
                public helpe: HelpersService,
                private toastr: ToastrService,
                private eveleCtrl: EleveCtrl,
                public ecoleModel: EleveModel,
                public http: HttpClient) {
    }

    ngOnInit() {
        $('select').select2();
        $(".select2-list").select2({
            allowClear: true
        });

        $('#datenaiss').datepicker({autoclose: true, todayHighlight: true, format: "dd/mm/yyyy"});
        $('#demo-date-month').datepicker({autoclose: true, todayHighlight: true, minViewMode: 1});
        $('#demo-date-format').datepicker({autoclose: true, todayHighlight: true, format: "yyyy/mm/dd"});
        $('#demo-date-range').datepicker({todayHighlight: true});
        $('#demo-date-inline').datepicker({todayHighlight: true});
    }

    handFile(file: FileList) {
        this.fileToUpload = file.item(0);
        let reader = new FileReader()
        reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
        }
        reader.readAsDataURL(this.fileToUpload);
    }

    onFile(event) {
        this.selectedtFil = <File>event.target.files[0];
    }


    setEleve(form: NgForm) {


        /*
         *recuperation des données via jQuery
         */
        this.ecoleModel.sexe = $("select[name='sexe'] option:selected").val().substring(0, 1);
        this.ecoleModel.nationalite = $("select[name='nation'] option:selected").val();
        this.ecoleModel.datenaiss = $("#datenaiss").val();

        /*
         * fin recuperation jQuery
         */


        /*     this.eveleCtrl.Upload(form.value).then((data)=>{
         console.log(data);
         }).catch((err)=>{
         console.log(err.message)
         }) */

        const endPoint = _SERVEROOT.av;
        const f = new FormData();

        // f.append('image', this.fileToUpload, this.fileToUpload.name);
        // f.append('photo', this.selectedtFil, this.selectedtFil.name);
        //
        //  this.http.post(endPoint, f, AUTHORIZATION.token).subscribe((data) => {
        //    console.log(data)
        //  }, (err) => {
        //
        //  })


        this.studentSubmitted = false;
        if (form.invalid) {
            this.scrollTo();
            return;
        }
        if (this.ecoleModel.nationalite == undefined || this.ecoleModel.nationalite == '') {
            return;
        }

        $("#sp").show();
        this.studentSubmitted = true;
        this.eveleCtrl.createEleves(this.ecoleModel)
            .then((data) => {
                $("#sp").hide();
                if (data.statut == true) {
                    this.elevesService.getStudent();
                    this.toastr.success(data.message, 'ELEVE');
                    form.resetForm();
                    this.nation = [];
                } else {

                    this.toastr.error(data.message, 'ELEVE');
                }
            })
            .catch((err) => {
                console.log(")-,-");
            })

    }


    scrollTo() {
        window.scrollTo(0, 0);
    }

    counter() {
        if (this.ecoleModel.contact.length <= 8) {
            this.countPhone = this.ecoleModel.contact.length;
            $(".cter").removeClass('text-danger')
        } else {
            $(".cter").addClass('text-danger');
        }
    }

    counterparent() {
        if (this.ecoleModel.code_parent.length <= 8) {
            this.countPhoneparent = this.ecoleModel.code_parent.length;
            $(".cterparent").removeClass('text-danger')
        } else {
            $(".cterparent").addClass('text-danger');
        }
    }

    countermat() {
        if (this.ecoleModel.matricule.length <= 9) {
            this.countmatricule = this.ecoleModel.matricule.length;
            $(".ctermat").removeClass('text-danger')
        } else {
            $(".ctermat").addClass('text-danger');
        }
    }


}
