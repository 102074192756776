import { Component, OnInit } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-facultes',
  templateUrl: './facultes.component.html',
  styleUrls: ['./facultes.component.css']
})
export class FacultesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  
  }

  consultation(params) {

    window.location.href = "#/consultation/" + params;
  }
}
