import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {_SERVEROOT, AUTHORIZATION} from '../../routes/server-root';
import {UsersModel} from '../models/usersModel.service';

declare let $: any;
declare let Promise: any;

@Injectable()
export class usersCtrl {
    constructor(public http: HttpClient) {
    }


    /*************************
     *
     *  Toutes les methodes pour les users
     *
     *************************
     */

    createUsers(data: UsersModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    nom: data.nom,
                    prenom: data.prenom,
                    fonction: data.fonction,
                    contact: data.contact,
                    sexe: data.sexe,
                    user_login: data.user_login,
                    role: data.role,
                    route: data.route,
                    localite: data.localite,
                    type_user: data.type_user,
                    date: data.date

                }
                ;
            this.http.post(_SERVEROOT.addUsers, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    updateUsers(data: UsersModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    nom: data.nom,
                    prenom: data.prenom,
                    fonction: data.fonction,
                    contact: data.contact,
                    sexe: data.sexe,
                    user_login: data.user_login,
                    role: data.user_login,
                    route: data.user_login,
                    localite: data.localite,
                    type_user: data.type_user,
                    date: data.date

                }
                ;
            this.http.post(_SERVEROOT.upUsers, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    getUsers(paraShow?) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listUsers + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole+"&type_user="+paraShow, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }
    getRegister_by_ets() {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listUsers_compte + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }


}