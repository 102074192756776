import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {_SERVEROOT, AUTHORIZATION} from '../../routes/server-root';
import {programModel} from "../models/programModel.service";
declare let $: any;
declare let Promise: any;

@Injectable()
export class programCtrl {
    constructor(public http: HttpClient) {
    }


    /*************************
     *
     *  Toutes les methodes pour du programme
     *
     *************************
     */

    createProgram(data: programModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    code_user: AUTHORIZATION.ecoParam.code_user,
                    id_annee: AUTHORIZATION.ecoParam.idannee,
                    lib_program: data.lib_program,
                    id_mat: data.id_mat,
                    id_classe: data.id_classe,
                    date_p: data.date_p,
                    heure_debut: data.heure_debut,
                    heure_fin: data.heure_fin

                }
                ;
            this.http.post(_SERVEROOT.addProgram, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }

    updateProgram(data: programModel) {
        return new Promise((resolve, reject) => {
            let insert: any = {
                    code_ecole: AUTHORIZATION.ecoParam.code_ecole,
                    code_user: AUTHORIZATION.ecoParam.code_user,
                    id_annee: AUTHORIZATION.ecoParam.idannee,
                    id: data.id,
                    id_mat: data.id_mat,
                    id_classe: data.id_classe,
                    date_p: data.date_p,
                    heure_debut: data.heure_debut,
                    heure_fin: data.heure_fin
                }
                ;
            this.http.post(_SERVEROOT.updateProgram, insert, AUTHORIZATION.token)
                .subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
        });
    }


    getProgramme(codeUser?: any) {
        return new Promise((resolve, reject) => {
            this.http.get(_SERVEROOT.listProgram + "?code_ecole=" + AUTHORIZATION.ecoParam.code_ecole + "&id_annee=" + AUTHORIZATION.ecoParam.idannee + "&code_user=" + codeUser, AUTHORIZATION.token)
                .subscribe(
                    (data) => {
                        resolve(data)
                    },
                    (err) => {
                        reject(err)
                    }
                )
        })
    }


}